import React, { useState, useEffect } from 'react'
import { globalState } from '../../../../store'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { usePost, useFetch } from '../../../../components/component-items/helpers'
import { OrderInfo } from './styles'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import Checkbox from '../../../../components/component-items/checkbox'
import { Form, InputGroup } from 'react-bootstrap'
import CardLoading from '../../../../components/component-items/loading-popover'
import { HoverNotes } from '../../../../components/component-items/hover-notes'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const ProductDetails = () => {
  const productVariant = useParams<{ id: string }>().id
  const url = `/api/product/variant/${productVariant}/details/`
  const {
    state: { csrf },
  } = globalState()

  const { response: resp, loaded, error }: any = useFetch(url, {})

  const productDetails = loaded ? resp.productDetails : {}

  const defaultVariant = {
    title: '',
    description: '',
    hsCode: '',
    inventoryControl: '',
    inventoryControlCategory: 'received_date',
    isBundle: false,
    syncInventory: false,
    signatureRequired: false,
    adultSignatureRequired: false,
  }

  const [variant, setVariant] = useState(defaultVariant)

  useEffect(() => {
    if (loaded) {
      if (Object.keys(productDetails).length !== 0 && productDetails.constructor === Object) {
        setVariant(productDetails)
      } else {
        setVariant(defaultVariant)
      }
    }
  }, [loaded])

  return (
    <OrderInfo style={{ display: 'block', position: 'relative', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Variant Details</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, width: '100%', overflow: 'hidden' }}>
        <Form.Group style={{ margin: '1em' }}>
          <Label>Title</Label>
          <Form.Control type="text" value={variant.title || ''} disabled />
          <Label>Description</Label>
          <Form.Control as="textarea" value={variant.description || ''} disabled />
          <Label>HS Code</Label>
          <Form.Control
            type="text"
            value={variant.hsCode || ''}
            onChange={(e) => setVariant({ ...variant, hsCode: e.target.value })}
          />
          <Label>Inventory Control</Label>
          <Form.Control type="text" value={variant.inventoryControl || ''} disabled />
          <Label>Inventory Control Category</Label>
          <Form.Control type="text" value={variant.inventoryControlCategory || ''} disabled />
          <InputGroup style={{ marginTop: '2em' }}>
            <Checkbox
              selected={variant.isBundle || false}
              setSelected={() => setVariant({ ...variant, isBundle: !variant.isBundle })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Bundle Product Variants</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={variant.syncInventory || false}
              setSelected={() => setVariant({ ...variant, syncInventory: !variant.syncInventory })}
            />
            <p style={{ padding: '0 1em' }}>
              <HoverNotes
                description="This does NOT initiate a sync. This lets Kase know that when syncing occurs to include this variant."
                title="Sync Inventory"
              />
            </p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={variant.signatureRequired || false}
              setSelected={() => setVariant({ ...variant, signatureRequired: !variant.signatureRequired })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Signature Required</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={variant.adultSignatureRequired || false}
              setSelected={() => setVariant({ ...variant, adultSignatureRequired: !variant.adultSignatureRequired })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Adult Signature Required</p>
          </InputGroup>
        </Form.Group>
        <div style={{ float: 'right' }}>
          <GlobalStyles.Button onClick={() => usePost(url, { ...variant }, csrf)}>Update</GlobalStyles.Button>
        </div>
      </div>
      {!loaded ? <CardLoading error={error} /> : null}
    </OrderInfo>
  )
}
