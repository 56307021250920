import React, { useState } from 'react'
import { useFetch } from './helpers'

// Components
import { MultiSelect } from './styles'
import CreatableSelect from 'react-select/creatable'

import { globalState } from '../../store'

export const CreateOrSelectCompanyEmail = ({ onChange }: { onChange: (option: any) => void }) => {
  const {
    state: { companyId },
  } = globalState()
  const { response: users }: any = useFetch(`/api/company/${companyId}/user/?all=true`, {})

  const [emailRecipients, setEmailRecipients] = useState<{ value: string }[]>([])

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (option: any) => {
    if (Array.isArray(option)) {
      const validEmails = option.filter((opt) => isValidEmail(opt.value))
      setEmailRecipients(validEmails)
      onChange(validEmails)
    }
  }

  const recipients =
    users?.map((option: any) => ({
      value: option.email,
      label: `${option.first_name} ${option.last_name} <${option.email}>`,
    })) || []

  return (
    <MultiSelect>
      <CreatableSelect
        options={recipients}
        value={emailRecipients}
        onChange={handleEmailChange}
        closeMenuOnSelect={false}
        isMulti
        className="basic-multi-select"
        formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
      />
    </MultiSelect>
  )
}
