import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useFetch, usePost, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import styled from 'styled-components'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import ButtonDropdown from '../../../components/component-items/button-dropdown'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { TableCell } from '../../../components/component-items/datatable'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt,
  faPrint,
  faTimesCircle,
  faCheckDouble,
  faTimes,
  faClipboardCheck,
} from '@fortawesome/pro-duotone-svg-icons'

// Enums
import { OrderStatus } from '../../../enums'

import {
  ShippingTableRowProps,
  ShipmentRateProps,
  CustomerLabelProps,
  DocumentProps,
  ReturnTypeProps,
  RatesModalProps,
} from './types'

const ShippingStyled = styled.div`
  width: 100%;
  display: block;
  padding: 0 15px 15px 15px;
  position: relative;
  .datatable__header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    min-height: 60px;
    padding: 0 10px;
    .datatable__title {
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const DataTable = styled(GlobalStyles.DataTable)`
  max-height: 500px;
`

const ShippingTableRow = ({
  shippingMethod,
  type,
  carrier,
  serviceLevel,
  tracking,
  exit_scanned,
  cost,
  url,
  printUrl,
  voided,
  id,
  order,
  returnItem,
  status,
}: ShippingTableRowProps) => {
  const {
    state: { csrf, userIsWarehouse },
  } = globalState()
  const canVoid =
    !voided &&
    tracking &&
    !returnItem &&
    ((type && type.includes('customer') && status < OrderStatus.Shipped) || userIsWarehouse)
  var labelVoidUrl =
    type === 'customer_document'
      ? `/api/order/${order}/delete_document/`
      : type === 'customer_label'
        ? `/api/order/${order}/delete_label/`
        : `/api/shipment/label/${id}/void/`
  return (
    <GlobalStyles.TableRow>
      <TableCell input={shippingMethod} />
      {tracking ? (
        <TableCell input={carrier}>
          <div className="input">{serviceLevel}</div>
        </TableCell>
      ) : null}
      {tracking ? (
        <TableCell
          input={
            <a
              href={url}
              rel="noreferrer"
              target="_blank"
              style={{ wordBreak: 'break-word', textDecoration: voided ? 'line-through' : '' }}
            >
              {tracking}
            </a>
          }
        />
      ) : null}
      {tracking ? (
        <TableCell
          center
          input={
            exit_scanned ? (
              <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckDouble} />
            ) : (
              <FontAwesomeIcon style={{ color: 'red' }} icon={faTimes} />
            )
          }
        />
      ) : null}
      {tracking ? <TableCell input={<span>{voided ? 'VOID' : '$' + cost}</span>} center /> : null}
      <TableCell
        input={
          <div className="dropdown__container">
            {tracking && !exit_scanned ? (
              <GlobalStyles.Tooltip data-title="Open exit scan page">
                <button onClick={() => window.open(`/warehouse/exit-scan/`)}>
                  <FontAwesomeIcon icon={faClipboardCheck} />
                </button>
              </GlobalStyles.Tooltip>
            ) : null}
            {tracking ? (
              <button onClick={() => window.open(type === 'return' ? printUrl : `/api/order/${order}/print_labels/`)}>
                <FontAwesomeIcon icon={faPrint} />
              </button>
            ) : null}
            {canVoid ? (
              <button
                onClick={() =>
                  window.confirm('Are you sure you want to VOID / Remove this item?')
                    ? labelVoidUrl === `/api/shipment/label/${id}/void/`
                      ? usePost(labelVoidUrl, {}, csrf)
                      : usePut(labelVoidUrl, { ...{ id } }, csrf)
                    : null
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            ) : null}
          </div>
        }
        center
      />
    </GlobalStyles.TableRow>
  )
}

const RatesModal = ({ productModal, setProductModal, order, statusId, shippingMethod }: RatesModalProps) => {
  const [method, setMethod] = useState()
  const [url, setUrl] = useState('')
  const {
    state: { csrf, userIsWarehouse },
  } = globalState()
  useEffect(() => {
    if (productModal) {
      setUrl(`/api/order/${order}/label_rates/`)
    }
  }, [productModal])

  const res: any = useFetch(url, {})
  const loaded = res.loaded
  const resp: any = loaded ? res.response : {}

  const results: ShipmentRateProps[] = loaded ? resp.results : []
  const shippingRateFound = loaded ? resp.shippingRateFound : true
  const dimensionsGuessed = loaded ? resp.dimensionsGuessed : false
  const badDims = loaded ? resp.bad_dims : false

  useEffect(() => {
    if (res.loaded) {
      setMethod(res.response.activeMethod)
    }
  }, [res.loaded])

  function getRateStatusBox(shippingRateFound: any) {
    if (!shippingRateFound) {
      return (
        <StatusBoxStyled className={'yellow'} style={{ margin: '1em', textAlign: 'center' }}>
          No matching shipping method found for shopping cart token: {shippingMethod} <br />
          NOTE: The tag below marked &quot;Selected&quot; will be chosen instead.
        </StatusBoxStyled>
      )
    } else {
      return (
        <StatusBoxStyled className={'blue'} style={{ margin: '1em', textAlign: 'center' }}>
          The following estimated rates are available for this order. Please keep in mind the final cost can change
          based on packaging and weight.
        </StatusBoxStyled>
      )
    }
  }

  function getDimStatusBox(bad_dims: any[], dimensionsGuessed: any) {
    if (bad_dims && bad_dims.length > 0) {
      return res.response.bad_dims.map((dim: any, i: number) => (
        <StatusBoxStyled key={i} className={'red'} style={{ margin: '1em', textAlign: 'center' }}>
          Invalid Dimensions for:{' '}
          <Link to={`/product/variant/${dim.id}/`} rel="noreferrer" target="__blank">
            {dim.name}
          </Link>
        </StatusBoxStyled>
      ))
    } else if (dimensionsGuessed) {
      return res.response.dimensions.map((dim: any, i: number) => (
        <StatusBoxStyled key={i} className={'yellow'} style={{ margin: '1em', textAlign: 'center' }}>
          Estimated Shipment Dimensions (L X W X H): {dim.length} x {dim.width} x {dim.height} ({dim.weight} OZ)
        </StatusBoxStyled>
      ))
    }
  }

  return (
    <GenericModal
      heading={'Shipping Rates'}
      show={productModal}
      size={'xl'}
      onHide={() => setProductModal(false)}
      buttons={
        <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setProductModal(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ minHeight: '500px' }}>
        {statusId < 160 ? (
          <>
            <div className="search-bar" style={{ padding: 10 }}>
              <InputGroup>
                <Form.Select value={method} onChange={(e: any) => setMethod(e.target.value)}>
                  <option value="bulk">Kase Bulk Rate</option>
                  <option value="pickup">Customer Pickup</option>
                  <option value="freight">LTL / Freight</option>
                  {res.loaded
                    ? res.response.shippingMethods.map((sm: any, id: number) => (
                        <option value={sm.id} key={id}>
                          {sm.remote_method}
                        </option>
                      ))
                    : null}
                </Form.Select>
                <GlobalStyles.Button
                  className="primary"
                  style={{ marginRight: 0, minWidth: 200 }}
                  onClick={() =>
                    usePut(`/api/order/${parseInt(order)}/change_shipping_rate/`, { shipping_method: method }, csrf)
                  }
                >
                  Set Shipping Method
                </GlobalStyles.Button>
              </InputGroup>
            </div>
            <div>{getRateStatusBox(shippingRateFound)}</div>
          </>
        ) : null}
        <div>
          {results.filter((a: any) => a.unavailable).length ? (
            <StatusBoxStyled className={'blue'} style={{ margin: '1em', textAlign: 'center' }}>
              You have some <strong>unavailable</strong> rates, which can be due to unsupported multi-piece shipments,
              or rates that don't meet your product specifications like USPS Media Mail
            </StatusBoxStyled>
          ) : null}
        </div>
        <div>
          {results.filter((a: any) => a.blacklisted).length ? (
            <StatusBoxStyled className={'blue'} style={{ margin: '1em', textAlign: 'center' }}>
              You have some <strong>blacklisted</strong> rates, which can be changed in your{' '}
              <Link to="/company/shipping-methods/">carrier / service level settings HERE</Link>.
            </StatusBoxStyled>
          ) : null}
        </div>
        <div>{getDimStatusBox(badDims, dimensionsGuessed)}</div>
        <DataTable>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">Carrier</span>
              </th>
              <th>
                <span className="text">Service Level</span>
              </th>
              <th>
                <span className="text center">Postage</span>
              </th>
              <th>
                <span className="text center">Estimated Days</span>
              </th>
              <th>
                <span className="text center">Guaranteed</span>
              </th>
              <th>
                <span className="text center">Tags</span>
              </th>
              {statusId < 160 ? (
                <th>
                  <span className="text center">Select</span>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {results
              .sort((a: ShipmentRateProps, b: ShipmentRateProps) => a.total - b.total)
              .map(
                ({
                  id,
                  image,
                  carrier,
                  service,
                  unavailable,
                  blacklisted,
                  selected,
                  disabled,
                  total,
                  estimatedDays,
                  guaranteedBy,
                }: ShipmentRateProps) =>
                  // Only show disabled postage if warehouse user
                  userIsWarehouse || (!userIsWarehouse && !disabled) ? (
                    <GlobalStyles.TableRow key={id}>
                      <TableCell
                        input={<img src={image} style={{ maxWidth: 50, maxHeight: 50 }} alt="carrier_logo" />}
                      />
                      <TableCell input={carrier} />
                      <TableCell input={service} style={unavailable ? { textDecoration: 'line-through' } : {}} />
                      <TableCell
                        center
                        input={
                          <StatusBoxStyled className="silver" style={{ width: 100, margin: 'auto' }}>
                            ${total}
                          </StatusBoxStyled>
                        }
                      />
                      <TableCell
                        center
                        input={
                          estimatedDays !== null ? (
                            <StatusBoxStyled className="blue" style={{ width: 100, margin: 'auto' }}>
                              {estimatedDays}
                            </StatusBoxStyled>
                          ) : null
                        }
                      />
                      <TableCell
                        center
                        input={
                          guaranteedBy ? (
                            <StatusBoxStyled className="green" style={{ width: 100, margin: 'auto' }}>
                              {guaranteedBy} Day
                            </StatusBoxStyled>
                          ) : (
                            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'purple' }} />
                          )
                        }
                      />
                      <TableCell
                        center
                        input={
                          selected ? (
                            <StatusBoxStyled className={'green'} style={{ width: 100, margin: 'auto' }}>
                              {statusId < 160 ? 'Selected' : 'Purchased'}
                            </StatusBoxStyled>
                          ) : unavailable ? (
                            <StatusBoxStyled className={'grey'} style={{ width: 100, margin: 'auto' }}>
                              Unavailable
                            </StatusBoxStyled>
                          ) : blacklisted ? (
                            <Link to="/company/shipping-methods/">
                              <StatusBoxStyled
                                className={'black'}
                                style={{ width: 100, margin: 'auto', cursor: 'pointer' }}
                              >
                                Blacklisted
                              </StatusBoxStyled>
                            </Link>
                          ) : disabled ? (
                            <StatusBoxStyled className={'grey'} style={{ width: 100, margin: 'auto' }}>
                              Disabled
                            </StatusBoxStyled>
                          ) : null
                        }
                      />
                      {statusId < 160 ? (
                        <TableCell center>
                          {!unavailable && !disabled ? (
                            <GlobalStyles.Button
                              className="secondary"
                              style={{ minWidth: 125 }}
                              onClick={() => usePut(`/api/order/${order}/change_rate/`, { ...{ id } }, csrf)}
                            >
                              Select
                            </GlobalStyles.Button>
                          ) : null}
                        </TableCell>
                      ) : null}
                    </GlobalStyles.TableRow>
                  ) : null
              )}
          </tbody>
        </DataTable>
        {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
      </div>
    </GenericModal>
  )
}

export const Shipping = () => {
  const {
    state: { csrf },
  } = globalState()

  const order = useParams<{ id: string }>().id

  const url = `/api/order/${order}/shipping/`
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})

  const statusId = loaded ? resp.status : 100
  const accounts = loaded ? resp.shippingAccounts : []
  const activeAccount = loaded ? resp.shippingAccountActive : 'None'
  const rates: ShipmentRateProps[] = loaded ? resp.rates : []
  const customerLabels: CustomerLabelProps[] = loaded ? resp.customerLabels : []
  const customerDocuments: DocumentProps[] = loaded ? resp.customerDocuments : []
  const returns: ReturnTypeProps[] = loaded ? resp.returns : []
  const shippingMethod = loaded ? resp.shippingMethod : 'Bulk'
  const freight = loaded ? resp.freight : false

  const accountArray = loaded ? Array.from(accounts, (x: any) => x.carrier__name + ' ' + x.billing_account) : []
  const accountList = ['None'].concat(accountArray)
  const [productModal, setProductModal] = useState(false)
  const [sort, setSort] = useState('None')

  useEffect(() => {
    if (activeAccount) {
      setSort(activeAccount)
    }
  }, [activeAccount])

  useEffect(() => {
    if (activeAccount !== sort && accounts.length) {
      const id = accounts.find((account: any) => account.carrier__name + ' ' + account.billing_account == sort)?.id
      usePut(`/api/order/${parseInt(order)}/change_shipping_account/`, { shipping_account: id }, csrf)
    }
  }, [sort])

  return (
    <ShippingStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Shipping</h3>
        </div>
        <div className="datatable__toolbar">
          {accounts.length ? (
            <ButtonDropdown {...{ sort, setSort }} title={'Shipping Account'} options={accountList} height={38} />
          ) : null}
          <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setProductModal(true)}>
            Rates
          </GlobalStyles.Button>
          <RatesModal {...{ productModal, setProductModal, order, statusId, shippingMethod }} />
        </div>
      </div>
      <DataTable>
        <thead>
          <tr>
            <th>
              <span className="text">Shipping Method</span>
            </th>
            {rates.length || customerLabels || customerDocuments ? (
              <th>
                <span className="text">Carrier</span>
              </th>
            ) : null}
            {rates.length || customerLabels || customerDocuments ? (
              <th>
                <span className="text">Tracking Number</span>
              </th>
            ) : null}
            {rates.length || customerLabels || customerDocuments ? (
              <th>
                <span className="text center">Exit Scanned</span>
              </th>
            ) : null}
            {rates.length || customerLabels || customerDocuments ? (
              <th>
                <span className="text" style={{ textAlign: 'center' }}>
                  Label Cost
                </span>
              </th>
            ) : null}
            <th>
              <span className="text" style={{ textAlign: 'center' }}>
                Actions
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {rates.length ? (
            rates.map((s: ShipmentRateProps, id) => (
              <ShippingTableRow
                shippingMethod={shippingMethod}
                carrier={s.serviceLevel.carrier}
                serviceLevel={s.serviceLevel.name}
                tracking={s.tracking}
                exit_scanned={s.exit_scanned}
                cost={s.cost}
                url={s.labelUrl}
                voided={s.voided}
                status={statusId}
                id={s.id}
                order={order}
                key={id}
              />
            ))
          ) : (
            <ShippingTableRow {...{ shippingMethod }} />
          )}
          {customerLabels.map((l: CustomerLabelProps, id: number) => (
            <ShippingTableRow
              type={'customer_label'}
              shippingMethod={'Customer Provided Labels'}
              carrier={'-'}
              tracking={l.name}
              url={l.labelUrl}
              cost={0}
              id={l.id}
              key={id}
              order={order}
              status={statusId}
            />
          ))}
          {customerDocuments.map((d: DocumentProps, id: number) => (
            <ShippingTableRow
              type={'customer_document'}
              shippingMethod={'Customer Provided Documents'}
              carrier={'-'}
              tracking={d.name}
              url={d.labelUrl}
              cost={0}
              id={d.id}
              key={id}
              order={order}
              status={statusId}
            />
          ))}
          {returns.map((r: ReturnTypeProps, id: number) => (
            <ShippingTableRow
              type="return"
              shippingMethod={<Link to={`/asn/${r.id}/`}>RETURN</Link>}
              carrier={r.carrier}
              serviceLevel={r.serviceLevel}
              tracking={r.tracking}
              cost={r.cost}
              url={r.labelUrl}
              voided={r.voided}
              printUrl={r.printUrl}
              order={order}
              status={statusId}
              returnItem
              key={id}
            />
          ))}
        </tbody>
      </DataTable>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </ShippingStyled>
  )
}
