import React, { useEffect, useRef, useState } from 'react'
import { notify, usePost } from '../../../../components/component-items/helpers'

// Components
import { ButtonGroup } from '../../../../components/component-items/buttons'
import { RecurringGroup, RecurringSection, RoyalShineButton, SubHeader } from './styles'
import { Col, Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import Select from 'react-select'

// Modals
import { CreateOrSelectCompanyEmail } from '../../../../components/component-items/create-or-select-company-email'
import { EndRecurrence } from '../../../../components/component-items/recurrence/ending'
import { RecurrenceYearly } from '../../../../components/component-items/recurrence/yearly'
import { RecurrenceMonthly } from '../../../../components/component-items/recurrence/monthly'
import { RecurrenceWeekly } from '../../../../components/component-items/recurrence/weekly'
import { recurrenceSummary } from './summary'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'
import { globalState } from '../../../../store'
import { RecurrenceCategory, RecurrenceTaskName } from '../../../../enums'
import { OrderStatusMultiSelect } from '../../../../components/component-items/select/order-multi-select'

// Enums
import { OrderStatus } from '../../../../enums'
import { colourStyles } from '../../../orders/filter'

export const FilterListItem = styled.div`
  margin: 0 auto 1em;
  width: 50%;
  &.large {
    width: 75%;
  }
`

export const Schedules = () => {
  const {
    state: { csrf, companyId },
  } = globalState()

  // Form data
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)

  // Recurrence Interval Section
  const [recurrenceInterval, setRecurrenceInterval] = useState(1)
  const [scheduleType, setScheduleType] = useState('daily')
  const scheduleOptions = [
    { label: 'Daily', value: 'daily', plural: 'day(s)', singular: 'day' },
    { label: 'Weekly', value: 'weekly', plural: 'week(s)', singular: 'week' },
    { label: 'Monthly', value: 'monthly', plural: 'month(s)', singular: 'month' },
    { label: 'Yearly', value: 'yearly', plural: 'year(s)', singular: 'year' },
  ]

  const [yearlyRecurrence, setYearlyRecurrence] = useState({})
  const [monthlyRecurrence, setMonthlyRecurrence] = useState({})
  const [weeklyRecurrence, setWeeklyRecurrence] = useState({})
  const [endRecurrence, setEndRecurrence] = useState({})

  async function handleSubmit(event: any, url: string) {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      notify({ type: 'error', title: 'Missing Information', message: 'Please fill out the entire form!' })
      return
    }

    let taskName = ''
    if (category === 'inventory_report') {
      taskName = RecurrenceTaskName.InventoryReport
    } else if (category === 'inventory_snapshot') {
      taskName = RecurrenceTaskName.InventorySnapshot
    } else if (category === 'order_report') {
      taskName = RecurrenceTaskName.OrderReport
    } else if (category === 'shipment_report') {
      taskName = RecurrenceTaskName.ShipmentReport
    } else {
      notify({ type: 'error', title: 'Invalid Category', message: 'Please select a valid category!' })
      return
    }

    var recurrenceData = {
      category: RecurrenceCategory.TypeReporting,
      taskName: taskName,
      recurrenceDescription: summary.textOutput,
      taskKwargs: {
        statuses: statuses.map((status: any) => status.value).join(','),
        date_range: dateRange,
        date_range_type: dateRangeType,
      },
      emailRecipients: emailRecipients.map((recipient: any) => recipient.value),
      scheduleType: scheduleType,
      recurrenceInterval: recurrenceInterval,
      ...endRecurrence,
    }
    if (scheduleType === 'yearly') {
      recurrenceData = { ...recurrenceData, ...yearlyRecurrence }
    } else if (scheduleType === 'monthly') {
      recurrenceData = { ...recurrenceData, ...monthlyRecurrence }
    } else if (scheduleType === 'weekly') {
      recurrenceData = { ...recurrenceData, ...weeklyRecurrence }
    }

    let response = await usePost(url, recurrenceData, csrf)
    if (response && response.success) {
      notify({ type: 'success', message: 'New Recurring Transaction Added!' })
    }
  }

  // Reporting section
  const [category, setCategory] = useState('inventory_report')
  const [statuses, setStatuses] = useState([])
  const [dateRangeType, setDateRangeType] = useState('today')
  const [dateTypeOptions, setDateTypeOptions] = useState<any>([])
  const [dateRange, setDateRange] = useState('today')
  const [emailRecipients, setEmailRecipients] = useState<{ value: string }[]>([])

  const [summary, setSummary] = useState<any>()
  useEffect(() => {
    setSummary(
      recurrenceSummary({
        recurrenceInterval,
        scheduleType,
        scheduleOptions,
        category,
        ...yearlyRecurrence,
        ...monthlyRecurrence,
        ...weeklyRecurrence,
        ...endRecurrence,
      })
    )
  }, [recurrenceInterval, scheduleType, yearlyRecurrence, monthlyRecurrence, weeklyRecurrence, endRecurrence, category])

  useEffect(() => {
    // Check if statuses only fulfilled (shipped and delivered)
    var is_fulfilled = true
    statuses.forEach(function (entry) {
      if (![OrderStatus.LabelPurchased, OrderStatus.Shipped, OrderStatus.Delivered].includes(entry['value'])) {
        is_fulfilled = false
      }
    })

    var is_shipped_or_delivered = true
    statuses.forEach(function (entry) {
      if (![OrderStatus.Shipped, OrderStatus.Delivered].includes(entry['value'])) {
        is_shipped_or_delivered = false
      }
    })

    var is_delivered = statuses.length === 1 && statuses[0]['value'] === OrderStatus.Delivered

    // 1. Label Purchased + Shipped + Delivered -> Show Fulfilled
    // 2. Shipped + Delivered -> Show Shipped + Fulfilled
    // 3. Delivered -> Show all 3

    // When order status is shipped or greater, show all options
    if (is_delivered) {
      setDateTypeOptions([
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between' },
        { value: 'delivered_between', label: 'Delivered Between' },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else if (is_shipped_or_delivered) {
      setDateTypeOptions([
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between' },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else if (statuses.length === 0 || is_fulfilled) {
      setDateTypeOptions([
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between [Invalid]', isDisabled: true },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else {
      setDateTypeOptions([
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between [Invalid]', isDisabled: true },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between [Invalid]', isDisabled: true },
      ])
      if (dateRangeType) setDateRangeType('')
    }
  }, [statuses])

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (option: any) => {
    if (Array.isArray(option)) {
      const validEmails = option.filter((opt) => isValidEmail(opt.value))
      setEmailRecipients(validEmails)
    }
  }

  const isOrderReport = ['order_report', 'shipment_report'].includes(category)
  const usesDateRange = ['inventory_report', 'order_report', 'shipment_report'].includes(category)

  return (
    <Form noValidate validated={validated} ref={node} onSubmit={(e) => handleSubmit(e, '/api/core/recurring-tasks/')}>
      <RecurringGroup style={{ marginTop: '3em' }}>
        <Form.Group as={Col} xl="9">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ButtonGroup aria-label="Reccurence">
              {scheduleOptions.map(({ label, value }, id: number) => (
                <Button
                  key={id}
                  variant="secondary"
                  active={scheduleType == value}
                  onClick={() => setScheduleType(value)}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </Form.Group>
        <Form.Group as={Col} xl="9">
          <SubHeader style={{ paddingTop: '2em' }}>
            Repeat every&nbsp;
            <Form.Control
              required
              type="number"
              isInvalid={!recurrenceInterval || recurrenceInterval < 1}
              value={recurrenceInterval}
              onChange={(e) => setRecurrenceInterval(parseInt(e.target.value))}
              style={{ width: 100, margin: '0 1em' }}
            />
            {scheduleOptions.find(({ value }) => value === scheduleType)?.plural || ''}
          </SubHeader>
        </Form.Group>
        {scheduleType === 'yearly' ? (
          <RecurrenceYearly {...{ setYearlyRecurrence }} />
        ) : scheduleType === 'monthly' ? (
          <RecurrenceMonthly {...{ setMonthlyRecurrence }} />
        ) : scheduleType === 'weekly' ? (
          <RecurrenceWeekly {...{ setWeeklyRecurrence }} />
        ) : null}
        <Form.Group as={Col} xl="9">
          <p style={{ paddingTop: '1em' }}>
            Multiple recurring transactions may be required if they span multiple intervals
          </p>
        </Form.Group>
        <EndRecurrence {...{ setEndRecurrence }} />
        <Form.Group as={Col} xl="9">
          <SubHeader>Report Details</SubHeader>
          <RecurringSection>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Form.Group style={{ width: 400 }} className="required">
                <Form.Label style={{ margin: '0 .25em', fontWeight: 500 }}>Category</Form.Label>
                <Form.Select value={category} onChange={(e) => setCategory(e.target.value)} required>
                  <optgroup label="Inventory">
                    <option value="inventory_report">Inventory Report</option>
                    <option value="inventory_snapshot">Inventory Snapshot</option>
                  </optgroup>
                  <optgroup label="Order">
                    <option value="order_report">Order Detail Report</option>
                    <option value="shipment_report">Shipment Detail Report</option>
                  </optgroup>
                </Form.Select>
              </Form.Group>
              {isOrderReport && (
                <>
                  <Form.Group style={{ width: 400 }}>
                    <Form.Label style={{ margin: '1em .25em 0', fontWeight: 500 }}>Order Status</Form.Label>
                    <OrderStatusMultiSelect {...{ statuses, setStatuses }} />
                  </Form.Group>
                  <Form.Group style={{ width: 400 }} className="required">
                    <Form.Label style={{ margin: '1em .25em 0', fontWeight: 500 }}>Date Type</Form.Label>
                    <Select
                      options={dateTypeOptions}
                      value={dateTypeOptions.find((option: any) => option.value === dateRangeType)}
                      onChange={(e: any) => setDateRangeType(e.value)}
                      styles={colourStyles}
                      required
                    />
                  </Form.Group>
                </>
              )}
              {usesDateRange && (
                <Form.Group style={{ width: 400 }} className="required">
                  <Form.Label style={{ margin: '1em .25em 0', fontWeight: 500 }}>Date Range</Form.Label>
                  <Form.Select value={dateRange} onChange={(e) => setDateRange(e.target.value)} required>
                    <optgroup label="Daily">
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="last_24_hours">Last 24 Hours</option>
                    </optgroup>
                    <optgroup label="Weekly">
                      <option value="last_7_days">Last 7 Days</option>
                      <option value="previous_week">Previous Week</option>
                      <option value="week_to_date">Week-to-Date</option>
                      <option value="trailing_7_days">Trailing 7 Days</option>
                    </optgroup>
                    <optgroup label="Monthly">
                      <option value="month_to_date">Month-to-Date (MTD)</option>
                      <option value="previous_month">Previous Month</option>
                      <option value="last_30_days">Last 30 Days</option>
                      <option value="trailing_4_weeks">Trailing 4 Weeks</option>
                    </optgroup>
                    <optgroup label="Quarterly & Yearly">
                      <option value="quarter_to_date">Quarter-to-Date (QTD)</option>
                      <option value="previous_quarter">Previous Quarter</option>
                      <option value="year_to_date">Year-to-Date (YTD)</option>
                      <option value="previous_year">Previous Year</option>
                    </optgroup>
                  </Form.Select>
                </Form.Group>
              )}
              <Form.Group style={{ width: 400 }}>
                <Form.Label style={{ margin: '1em .25em 0', fontWeight: 500 }}>Email Recipients</Form.Label>
                <CreateOrSelectCompanyEmail onChange={handleEmailChange} />
              </Form.Group>
            </div>
          </RecurringSection>
        </Form.Group>
        <Form.Group as={Col} xl="9">
          <SubHeader style={{ paddingTop: '2em' }}>Summary</SubHeader>
          <StatusBarStyled
            style={{ backgroundColor: '#f4f7ff', marginBottom: 0, borderRadius: '5px', border: '1px solid #c7c9e5' }}
          >
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
              </div>
              <div className="status-text">{summary?.htmlOutput}</div>
            </div>
          </StatusBarStyled>
        </Form.Group>
        <div style={{ float: 'right', margin: '2em 0' }}>
          <RoyalShineButton className="royal" onClick={(e: any) => handleSubmit(e, '/api/core/recurring-tasks/')}>
            Schedule Recurring Report
          </RoyalShineButton>
        </div>
      </RecurringGroup>
    </Form>
  )
}
