import React from 'react'
import { Link } from 'react-router-dom'
import { Styled } from './styles'

export const NavigationFooter = () => (
  <Styled.NavigationFooter>
    <div className="footer_container">
      <div className="footer__copyright">
        {new Date().getFullYear()}&nbsp;&copy;&nbsp;
        <a href="https://shippingtree.co" rel="noreferrer" target="_blank">
          Kase
        </a>
      </div>
      <div className="footer__menu">
        <a href="https://shippingtree.co/about-us/" rel="noreferrer" target="_blank">
          About
        </a>
        <Link to="/billing/pricing/">Pricing</Link>
        <a href="https://shippingtree.co/contact/" rel="noreferrer" target="_blank">
          Contact
        </a>
      </div>
    </div>
  </Styled.NavigationFooter>
)
