import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost, usePut } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import { useTheme } from 'styled-components'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import { Pages } from '../../components/component-items/pager'
import ButtonDropdown from '../../components/component-items/button-dropdown'
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import { TableCell } from '../../components/component-items/datatable'
import { StatusBarStyled } from '../../components/component-items/status-bar'

import { TableRow } from './styles'
import { Form } from 'react-bootstrap'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashRestore,
  faInfoSquare,
  faUserSlash,
  faExclamationTriangle,
} from '@fortawesome/pro-duotone-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

import { EditUserProps, EditUserModalProps } from './types'

const DeactivateUserModal = ({ userId, email }: EditUserModalProps) => {
  const [open, setOpen] = useState(false)
  const {
    state: { csrf, companyId },
  } = globalState()
  const theme = useTheme()

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        onClick={() => usePut(`/api/company/${companyId}/user/${userId}/toggle_active/`, {}, csrf)}
        className="primary"
        style={{ minWidth: 125 }}
      >
        Deactivate
      </GlobalStyles.Button>
    </>
  )
  return (
    <>
      <button onClick={() => setOpen(true)}>
        <GlobalStyles.Tooltip data-title="Deactivate user">
          <FontAwesomeIcon icon={faUserSlash} />
        </GlobalStyles.Tooltip>
      </button>
      <GenericModal heading={'Deactivate User'} show={open} onHide={() => setOpen(false)} buttons={buttons}>
        <div style={{ margin: '0 2em' }}>
          <StatusBarStyled>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: theme.colors.radicalRed }} />
              </div>
              <div className="status-text">
                <strong style={{ fontWeight: 500 }}>Note</strong>: By clicking 'Deactivate', the user with the email{' '}
                <strong style={{ fontWeight: 500 }}>{email}</strong> will no longer be able to login and access company
                data.
              </div>
            </div>
          </StatusBarStyled>
        </div>
      </GenericModal>
    </>
  )
}

const ActivateUserModal = ({ userId, email }: EditUserModalProps) => {
  const [open, setOpen] = useState(false)
  const {
    state: { csrf, companyId },
  } = globalState()
  const theme = useTheme()

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        onClick={() => usePut(`/api/company/${companyId}/user/${userId}/toggle_active/`, {}, csrf)}
        className="primary"
        style={{ minWidth: 125 }}
      >
        Activate
      </GlobalStyles.Button>
    </>
  )
  return (
    <>
      <button onClick={() => setOpen(true)}>
        <GlobalStyles.Tooltip data-title="Activate user">
          <FontAwesomeIcon icon={faTrashRestore} />
        </GlobalStyles.Tooltip>
      </button>
      <GenericModal heading={'Activate User'} show={open} onHide={() => setOpen(false)} buttons={buttons}>
        <div style={{ margin: '0 2em' }}>
          <StatusBarStyled>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: theme.colors.radicalRed }} />
              </div>
              <div className="status-text">
                <strong style={{ fontWeight: 500 }}>Note</strong>: By clicking 'Activate', the user with the email{' '}
                <strong style={{ fontWeight: 500 }}>{email}</strong> will now be able to login and access company data.
              </div>
            </div>
          </StatusBarStyled>
        </div>
      </GenericModal>
    </>
  )
}

export const EditUser = ({ currentUser, open, setOpen }: EditUserProps) => {
  const defaultUser = { id: null, first_name: '', last_name: '', email: '', username: '', is_admin: false }
  const [user, setUser] = useState(defaultUser)
  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement>(null)
  const {
    state: { csrf, warehouseActive, companyId },
  } = globalState()

  useEffect(() => {
    if (Object.keys(currentUser).length === 0 && currentUser.constructor === Object) {
      setUser(defaultUser)
    } else {
      setUser(currentUser)
    }
  }, [currentUser])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    if (user.id) {
      usePut(`/api/company/${companyId}/user/${user.id}/`, { ...user }, csrf)
    } else {
      usePost(`/api/company/${companyId}/user/`, { ...user }, csrf)
    }
  }

  return (
    <GenericModal
      heading={user.id ? 'Update User' : 'Create User'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{user.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form ref={node} noValidate validated={validated} style={{ margin: '2em' }}>
        <Form.Group className="required">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            value={user.first_name}
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="required">
          <Form.Label style={{ marginTop: '1em' }}>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            value={user.last_name}
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid last name.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="required">
          <Form.Label style={{ marginTop: '1em' }}>Email</Form.Label>
          <Form.Control
            required
            type="text"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="required">
          <Form.Label style={{ marginTop: '1em' }}>Username</Form.Label>
          <Form.Control
            required
            type="text"
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid username.</Form.Control.Feedback>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const CompanyUsers = () => {
  const {
    state: { companyId },
  } = globalState()
  const theme = useTheme()
  const options = ['Active', 'Inactive']
  const [sort, setSort] = useState('Active')
  const [page, setPage] = useState(1)

  const url = `/api/company/${companyId}/user/?page=${page}&type=` + sort
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})
  const { count: total, results: results }: any = loaded ? resp : {}

  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({})
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (loaded && results && totalPages === 1) {
      setTotalPages(Math.ceil(total / (results.length || 1)))
    }
  }, [loaded])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Company Users<small>{total} Total</small>
          </h3>
          <ButtonDropdown sort={sort} setSort={setSort} options={options} height={38} />
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            onClick={() => {
              setUser({})
              setOpen(true)
            }}
          >
            Add User
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <StatusBarStyled style={{ backgroundColor: theme.colors.ghostWhite }}>
        <div className="header-item">
          <div className="status-icon">
            <FontAwesomeIcon icon={faInfoSquare} style={{ color: theme.colors.freshEggplant }} />
          </div>
          <div className="status-text">
            Deactivating a user by clicking <FontAwesomeIcon icon={faUserSlash} /> under Actions marks a user inactive.
            This action will make it so the user can no longer login. You can reactivate them at any time.
          </div>
        </div>
      </StatusBarStyled>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Name</span>
              </th>
              <th>
                <span className="text">Email</span>
              </th>
              <th>
                <span className="text">Username</span>
              </th>
              <th>
                <span className="text center">Admin</span>
              </th>
              <th>
                <span className="text center">Last Login</span>
              </th>
              <th>
                <span className="text center">Date Joined</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {results?.map((u: any, id: number) => (
              <TableRow key={id}>
                <TableCell input={u.first_name + ' ' + u.last_name} />
                <TableCell input={u.email} />
                <TableCell input={u.username} />
                <TableCell center input={u.is_admin ? <FontAwesomeIcon icon={faCheck} /> : ''} />
                <TableCell
                  className={'hide-in-percy'}
                  center
                  input={u.last_login ? new Date(u.last_login).toLocaleDateString('en-US') : ''}
                />
                <TableCell center input={new Date(u.date_joined).toLocaleDateString('en-US')} />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          setUser(u)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      {sort == 'Active' ? (
                        <DeactivateUserModal userId={u.id} email={u.email} />
                      ) : (
                        <ActivateUserModal userId={u.id} email={u.email} />
                      )}
                    </div>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
        <>
          <br />
          <Pages page={page} setPage={(page: number) => setPage(page)} total={totalPages} />
          <br />
        </>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <EditUser {...{ open, setOpen }} currentUser={user} />
    </GlobalStyles.FullPageCard>
  )
}
