import React, { useState } from 'react'
import { usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'
import { theme as customTheme } from '../../../theme/KaseTheme'

// Enums
import { OrderStatus } from '../../../enums'

import { CancelOrderModalProps } from './types'

export const CancelOrderModal = ({
  order,
  cancelModal,
  setCancelModal,
  isWarehouse,
  statusId,
}: CancelOrderModalProps) => {
  const [waiveFee, setWaiveFee] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  return (
    <GenericModal
      heading={'Cancel Order'}
      show={cancelModal}
      onHide={() => setCancelModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => usePut(`/api/order/${parseInt(order)}/mark_order_cancelled/`, { waive_fee: waiveFee }, csrf)}
        >
          Confirm
        </GlobalStyles.Button>
      }
    >
      <p style={{ fontSize: '1.25rem', textAlign: 'center' }}>Please confirm you wish to cancel this order.</p>

      {isWarehouse ? (
        <div className="search-bar" style={{ padding: 10 }}>
          <InputGroup style={{ justifyContent: 'center' }}>
            <Checkbox selected={waiveFee} setSelected={setWaiveFee} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Waive $5.00 cancellation fee</p>
          </InputGroup>
        </div>
      ) : (
        <StatusBoxStyled className={'yellow'} style={{ width: '75%', margin: 'auto' }}>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: customTheme.colors.mySin }} />
          {statusId >= OrderStatus.Processing
            ? ' A $5.00 cancellation fee will be charged as the order has started processing'
            : ' No fee will be applied as this order has not begun processing.'}
        </StatusBoxStyled>
      )}
    </GenericModal>
  )
}
