import React from 'react'
import { StickyElement } from '../../../components/component-items/helpers'
import { MenuOptions } from './styles'
import { Col, Row } from 'react-bootstrap'
import { notify, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'

// Cards
import { StatusBar } from './status-bar'
import { TwoFactorOptions } from './security/two-factor-options'
import { ProfileSettings } from './settings'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faAsterisk, faSignOutAlt } from '@fortawesome/pro-duotone-svg-icons'

import { MenuOptionProps } from './types'
import { ProfileSummary } from './summary'
import { ChangePassword } from './security/change_password'

const MenuOption = ({ url, onClick, icon, title, arrow, children, node }: MenuOptionProps) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const Profile = () => {
  const [open, setOpen] = React.useState(false)
  const {
    state: { csrf },
  } = globalState()

  const onSubmit = () => {
    const response: any = usePost('/api/logout/', {}, csrf, false, true, true)
    if (response?.ok) {
      window.location.href = '/'
    }
  }
  const handleLogout = () => {
    notify({
      type: 'warning',
      message: 'Are you sure you want to sign out?',
      requestConfirmation: true,
      onSubmit: onSubmit,
      autoClose: false,
    })
  }

  return (
    <>
      <StatusBar />
      <Row>
        <Col xl={2}>
          <StickyElement>
            <MenuOptions>
              <ul>
                <GlobalStyles.Tooltip data-title="Change your password">
                  <MenuOption onClick={() => setOpen(true)} icon={faAsterisk} title={'Change Password'} />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="Logout of Kase">
                  <MenuOption onClick={handleLogout} icon={faSignOutAlt} title={'Sign Out'} />
                </GlobalStyles.Tooltip>
              </ul>
            </MenuOptions>
          </StickyElement>
        </Col>
        <Col xl={8}>
          <Row>
            <Col xs={12}>
              <ProfileSettings />
            </Col>
            <Col xs={12}>
              <TwoFactorOptions />
            </Col>
          </Row>
        </Col>
        {/* <Col xl={3}>
          <StickyElement>
            <ProfileSummary />
          </StickyElement>
        </Col> */}
      </Row>
      <ChangePassword {...{ open, setOpen }} />
    </>
  )
}
