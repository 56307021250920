import React, { useState, useRef } from 'react'
import { globalState } from '../../store'

// Components
import { usePost, notify } from '../../components/component-items/helpers'
import 'react-phone-input-2/lib/bootstrap.css'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import GlobalStyles from '../../components/component-items/styles'
import { SMSVerificationProps } from './types'
import { H2, TwoFactorAuth } from './styles'

// Enums
import {
  TwoFactorAuthenticationIcons,
  TwoFactorAuthenticationMethods,
  TwoFactorAuthenticationDisplays,
} from '../../enums'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SMSVerification = ({ userID, phone, getSession }: SMSVerificationProps) => {
  const [validated, setValidated] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const ref: any = useRef()
  const {
    state: { csrf },
  } = globalState()

  const handleVerification = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (ref?.current?.checkValidity() === false) {
      return
    }

    const resp = await usePost(
      '/api/2fa/verify/phone/',
      { userID: userID, phone: phone, token: verificationCode },
      csrf,
      false
    )
    if (resp.success) {
      getSession()
    }
  }

  const resendVerification = async (event: any) => {
    const resp = await usePost('/api/2fa/setup/phone/', { phone: phone }, csrf, false)
    if (resp.success) {
      notify({
        title: 'Success!',
        message: 'A new verification code has been sent to your phone.',
        type: 'success',
      })
    }
  }

  return (
    <TwoFactorAuth>
      <H2>
        Device Verification <br />
        <FontAwesomeIcon
          size="2x"
          icon={TwoFactorAuthenticationIcons[TwoFactorAuthenticationMethods.SMS]}
          style={{ marginTop: '20px' }}
        />
        <br />
        <span style={{ fontSize: '.7em' }}>{TwoFactorAuthenticationDisplays[TwoFactorAuthenticationMethods.SMS]}</span>
      </H2>

      <Row style={{ justifyContent: 'center', marginTop: '20px' }}>
        <Col md="8">
          <Form noValidate ref={ref} validated={validated} onSubmit={handleVerification}>
            <Form.Group className="required">
              <p>
                We just sent your authentication code via text message to the phone number ending in {phone.slice(-4)}
                .{' '}
              </p>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="000000"
                  required
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please enter a 6 digit code</Form.Control.Feedback>
              </InputGroup>
              <GlobalStyles.Button style={{ minWidth: '100%', margin: '1em 0 0' }} onClick={handleVerification}>
                Verify
              </GlobalStyles.Button>
              <div style={{ marginTop: '25px' }}>
                <h6 style={{ textAlign: 'center' }}>Having trouble verifying?</h6>
                <ul>
                  <li>
                    <a href="#" onClick={resendVerification}>
                      Re-send authentication code
                    </a>
                  </li>
                </ul>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </TwoFactorAuth>
  )
}
