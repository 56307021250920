// Import External Dependencies
import React from 'react'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { theme as customTheme } from '../theme/KaseTheme'
import 'react-toastify/dist/ReactToastify.css'

const StyledToastContainer = styled(ToastContainer)<{
  customTheme: typeof customTheme
}>`
  &&&.Toastify__toast-container {
    .Toastify__toast {
      cursor: default;
      box-shadow: rgba(82, 63, 105, 0.5) 0px 0px 13px 0px;
    }
    .Toastify__toast--info {
      &:has(.confirm) {
        border-left: 5px solid ${customTheme.colors.darkPurple};
      }
      .Toastify__progress-bar {
        background: ${(props) => props.customTheme.colors.darkPurple};
      }
    }
    .Toastify__toast--success {
      &:has(.confirm) {
        border-left: 5px solid ${customTheme.colors.emeraldGreen};
      }
      .Toastify__progress-bar {
        background: ${(props) => props.customTheme.colors.emeraldGreen};
      }
    }
    .Toastify__progress-bar--success {
      &:has(.confirm) {
        border-left: 5px solid ${customTheme.colors.javaBlue};
      }
      .Toastify__progress-bar {
        background: ${(props) => props.customTheme.colors.javaBlue};
      }
    }
    .Toastify__toast--warning {
      &:has(.confirm) {
        border-left: 5px solid ${customTheme.colors.mySin};
      }
      .Toastify__progress-bar {
        background: ${(props) => props.customTheme.colors.mySin};
      }
    }
    .Toastify__toast--error {
      ${({ autoClose, customTheme }) =>
        !autoClose &&
        `
        border-left: 5px solid ${customTheme.colors.crimson};
      `}
      .Toastify__progress-bar {
        background: ${(props) => props.customTheme.colors.crimson};
      }
    }
  }
`

// Create and export the component
export const KaseToastContainer = () => {
  return <StyledToastContainer position="top-right" theme="light" customTheme={customTheme} autoClose={4000} />
}

export default KaseToastContainer
