import React, { useEffect, useState } from 'react'
import { useFetch, usePost, usePut } from '../../../components/component-items/helpers'
import GlobalStyles from '../../../components/component-items/styles'
import { Form, Modal, Badge } from 'react-bootstrap'
import { globalState } from '../../../store'
import { EDI_API_ENDPOINTS } from '../../../api/api_endpoints'
import { EDIRetailer, GS1LabelType } from '../../../types/edi_types'

export const EDIRetailerReferenceModal = ({ open, setOpen, retailer }: any) => {
  const {
    state: { csrf },
  } = globalState()

  const [validated, setValidated] = useState(false)
  const [retailerSettings, setRetailerSettings] = useState(retailer || {})
  const [gs1LabelTypes, setGs1LabelTypes] = useState<GS1LabelType[]>([])
  const [ediRetailers, setEDIRetailers] = useState<EDIRetailer[]>([])
  const [filteredGs1Labels, setFilteredGs1Labels] = useState<string[]>([])

  useEffect(() => {
    setRetailerSettings(retailer || {})
  }, [open])

  const { response: gs1LabelTypesResp } = useFetch(EDI_API_ENDPOINTS.getGs1LabelTypes, {})
  useEffect(() => {
    if (gs1LabelTypesResp && gs1LabelTypesResp.results) {
      setGs1LabelTypes(gs1LabelTypesResp.results)

      const retailersMap = new Map()
      gs1LabelTypesResp.results.forEach(({ retailerId, retailerName }: any) => {
        if (!retailersMap.has(retailerId)) {
          retailersMap.set(retailerId, retailerName)
        }
      })
      const retailerArray: EDIRetailer[] = Array.from(retailersMap.entries()).map(([retailerId, retailerName]) => ({
        retailerId,
        retailerName,
      }))

      setEDIRetailers(retailerArray)
    }
  }, [gs1LabelTypesResp])

  useEffect(() => {
    if (retailerSettings.ediRetailer) {
      const labels = gs1LabelTypes
        .filter((label) => label.retailerId === parseInt(retailerSettings.ediRetailer))
        .map((label) => label.name)
      setFilteredGs1Labels(labels)
    } else {
      setFilteredGs1Labels([])
    }
  }, [retailerSettings.ediRetailer, gs1LabelTypes])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement | HTMLAnchorElement>) => {
    const form: any = event.currentTarget

    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (form?.checkValidity() === false || !retailerSettings.ediRetailer) {
      return
    }
    if (retailer) {
      usePut(EDI_API_ENDPOINTS.updateRetailer(retailerSettings.id), retailerSettings, csrf)
    } else {
      usePost(EDI_API_ENDPOINTS.createRetailer, retailerSettings, csrf)
    }
  }
  return (
    <Modal show={open} onHide={() => setOpen(false)} size="lg" centered>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{retailer ? 'Edit Retailer Reference' : 'Add Retailer Reference'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: '0 auto', maxWidth: 400 }}>
            <Form.Group className="required">
              <Form.Label style={{ display: 'inline-flex' }}>
                <GlobalStyles.Tooltip
                  className="subheader__button button"
                  data-title="Select the EDI Retailer Name"
                  style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
                >
                  Retailer
                </GlobalStyles.Tooltip>
              </Form.Label>
              <Form.Select
                value={retailerSettings.ediRetailer || ''}
                isInvalid={validated && !retailerSettings.ediRetailer}
                onChange={(e) => setRetailerSettings((s: any) => ({ ...s, ediRetailer: e.target.value }))}
              >
                <option hidden>-- Select --</option>
                {ediRetailers.map(({ retailerId, retailerName }) => (
                  <option value={retailerId} key={retailerId}>
                    {retailerName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please select a valid EDI Retailer.</Form.Control.Feedback>
            </Form.Group>
            {filteredGs1Labels.length > 0 && (
              <div style={{ marginTop: '1em' }}>
                <Form.Label>Supported GS1 Labels</Form.Label>
                <div>
                  {filteredGs1Labels.map((label, index) => (
                    <Badge key={index} bg="primary" style={{ marginRight: 5, marginBottom: 5 }}>
                      {label}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            <Form.Group className="required" style={{ marginTop: '1em' }}>
              <Form.Label style={{ display: 'inline-flex' }}>
                <GlobalStyles.Tooltip
                  className="subheader__button button"
                  data-title="Found in your routing guidelines."
                  style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
                >
                  Retailer Reference ID
                </GlobalStyles.Tooltip>
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Reference ID"
                value={retailerSettings.referenceId}
                onChange={(e) => setRetailerSettings((s: any) => ({ ...s, referenceId: e.target.value }))}
              />
              <Form.Control.Feedback type="invalid">
                Please enter the reference ID of the retailer.
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <GlobalStyles.Button style={{ minWidth: 125 }}>Save</GlobalStyles.Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
