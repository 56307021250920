import styled from 'styled-components'
import { device } from '../../../../components/component-items/device'

export const Tag = styled.div`
  display: inline-block;
  padding: 4px 7px;
  border-radius: 15px;
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 5px;
  margin-left: 2px;

  @media ${device.laptop} {
    margin-left: 7px;
  }
  &.configured {
    color: #28a745;
    border: 1px solid #28a745;
  }
  &.primary {
    color: #007bff;
    border: 1px solid #007bff;
  }
  &.recommended {
    color: black;
    border: 1px solid black;
  }
`
