import React from 'react'

// Components
import GlobalStyles from '../../../components/component-items/styles'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

// Components
import { ProductRow } from '../index'

export const ProductVariantListFiltered = ({ productVariants }: { productVariants: any[] }) => {
  return (
    <div style={{ minHeight: 100, maxHeight: 500, overflow: 'scroll' }}>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th />
            <th>
              <span className="text">Product</span>
            </th>
            <th>
              <span className="text">SKU</span>
            </th>
            <th>
              <span className="text center">On Hand</span>
            </th>
            <th>
              <span className="text center">On Order</span>
            </th>
            <th>
              <span className="text center">Defective</span>
            </th>
            <th>
              <span className="text center">Production</span>
            </th>
            <th>
              <span className="text center">Available</span>
            </th>
            <th>
              <span className="text center">Inbound</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {productVariants.map((o: any, id: number) => (
            <ProductRow {...o} key={id} />
          ))}
        </tbody>
      </GlobalStyles.DataTable>
    </div>
  )
}
