import React, { useState, useRef, FunctionComponent, useEffect } from 'react'
import { notify, useDelete, useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

import Form from 'react-bootstrap/Form'
import styled from 'styled-components'

// DateRange Component
import { DateTimePicker } from '@vaadin/react-components/DateTimePicker.js'
import { useSignal } from '@vaadin/hilla-react-signals'
import { format } from 'date-fns'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { TableCell } from '../../../components/component-items/datatable'
import GenericModal from '../../../components/component-items/modal'
import Pager, { Pages } from '../../../components/component-items/pager'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBarcodeRead,
  faCheckDouble,
  faDownload,
  faFolderOpen,
  faTrashAlt,
  faBoxAlt,
} from '@fortawesome/pro-duotone-svg-icons'
import { useTheme } from 'styled-components'
import { LargeButton } from '../../../components/component-items/buttons'
import { AdvancedSelect, filterOptionsURLParams } from '../../../components/component-items/advanced-select'
import { SearchBox } from '../../../components/component-items/search'
import { ShipmentList } from './shipments'

type CarrierProps = {
  id: number
  carrier: string
  count: number
}

type CarrierListProps = {
  id: number
  name: string
  service_levels: any[]
  active: boolean
  freight: boolean
  image: string
  scac: string
  manifest_enabled: boolean
}

type ManifestsProps = {
  id: number
  created: string
  expected_pickup_time: string
  carrier_name: string
  service_level_name: string
  location_barcode: string
  sscc_barcode_char: string
  total_shipments: number
  total_weight: number
  open: boolean
  title: string
  url: string
}

type CreateManifestProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

const dateOptions: any = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const timeOptions: any = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

export const DateRangeStyled = styled.div`
  display: flex;
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
  h5 {
    font-size: 1.2em;
    text-align: center;
    margin-top: 1em;
    width: 100%;
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
`

export const CreateManifest: FunctionComponent<CreateManifestProps> = ({ open, setOpen }) => {
  const node: any = useRef()
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)

  const [carrier, setCarrier] = useState<any>(null)
  const [serviceLevel, setServiceLevel] = useState<any>(null)
  const [location, setLocation] = useState('')
  const [expectedPickupTime, setExpectedPickupTime] = useState()
  const {
    state: { csrf },
  } = globalState()

  const { response, loaded, error, placeholder } = useFetch('/api/shipment/carrier/?manifest_enabled=True', {})

  const carriers: CarrierListProps[] = response || []
  const [serviceLevels, setServiceLevels] = useState<any[]>([])

  useEffect(() => {
    if (carrier) {
      let serviceLevels = carriers.find(({ id }) => `${id}` === carrier)?.service_levels || []
      setServiceLevels(serviceLevels)
      setServiceLevel(null)
    }
  }, [carrier])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    if (!carrier || !location || !expectedPickupTime) {
      notify({
        type: 'warning',
        message: 'Please fill out all required fields.',
      })
      return
    }

    setLoading(true)

    let resp = await usePost(
      '/api/postage/manifest/',
      { ...{ carrier, service_level: serviceLevel, location, expected_pickup_time: expectedPickupTime } },
      csrf,
      false,
      true,
      true
    )
    if (resp && !resp.errors) {
      setCarrier(null)
      setServiceLevel(null)
      notify({
        type: 'success',
        message: 'PostageManifest created successfully!',
      })
      window.location.href = `/api/postage/manifest/${resp.id}/print_sscc/`

      // Delay the reload
      setTimeout(() => {
        window.location.reload()
      }, 2000) // Adjust delay as needed
    } else {
      notify({
        type: 'error',
        title: 'Postage Manifest Failed!',
        message: resp.errors,
      })
      setLoading(false)
    }
  }

  const minDate = useSignal(new Date())

  return (
    <GenericModal
      heading={'Create Manifest'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{'Submit'}</GlobalStyles.Button>}
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '1em 2em' }} className="required">
          <Form.Label>Carrier</Form.Label>
          <Form.Select
            required
            value={carriers.find(({ id }) => id === carrier)?.name}
            onChange={(e) => setCarrier(e.target.value)}
          >
            <option value={''} hidden>
              -- Select Carrier --
            </option>
            {carriers.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group style={{ margin: '1em 2em' }}>
          <Form.Label>Service Level</Form.Label>
          <Form.Select
            value={serviceLevels.find(({ id }) => id === serviceLevel)}
            onChange={(e) => setServiceLevel(e.target.value)}
          >
            <option value={''}>-- Select Service Level --</option>
            {carriers
              .find(({ id }) => `${id}` === carrier)
              ?.service_levels?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group style={{ margin: '1em 2em' }} className="required">
          <Form.Label>Location</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Location Barcode"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter the warehouse location for this container.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group style={{ margin: '1em 2em' }} className="required">
          <Form.Label>Expected Pickup Time</Form.Label>
          <DateTimePicker
            value={expectedPickupTime}
            onValueChanged={(e: any) => setExpectedPickupTime(e.detail.value)}
            step={60 * 15} // Step size in seconds (15 minutes)
            min={format(minDate.value, `yyyy-MM-dd'T'HH:00:00`)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter the warehouse location for this container.
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
      {loading ? <CardLoading text={placeholder} error={error} /> : null}
    </GenericModal>
  )
}

export const PostageManifests = () => {
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [openCreateManifest, setOpenCreateManifest] = useState(false)
  const [openShipmentsModal, setOpenShipmentsModal] = useState(false)
  const [selectedManifest, setSelectedManifest] = useState<number>()
  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  const theme: any = useTheme()
  const {
    state: { csrf, userIsManager, manifestFilterOptions },
    dispatch,
  } = globalState()

  const [filterOptions, setFilterOptions] = useState(manifestFilterOptions || [])
  const setDefaultFilter = () => {
    dispatch({ type: 'manifestFilterOptions', manifestFilterOptions: filterOptions })
  }

  // TODO - Add pagination to the URL
  const offset = (page - 1) * rows
  const url = `/api/postage/manifest/?limit=${rows}&offset=${offset}&${filterOptionsURLParams(filterOptions)}&q=${search}`
  const { response, loaded, error, placeholder } = useFetch(url, {})
  const manifests: ManifestsProps[] = loaded ? (response as any).results : []

  useEffect(() => {
    if (loaded) {
      setLoading(false)
    }
  }, [loaded])

  const total = loaded ? (response as any)?.count : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  const handleDownload = (url: string, id: any) => {
    window.open(url, '__blank')
    // Mark manifest as downloaded
    usePost(`/api/postage/manifest/${id}/last_downloaded_now/`, {}, csrf)
  }

  return (
    <Row>
      <Col xl={9}>
        <GlobalStyles.FullPageCard>
          <GlobalStyles.CardHeader>
            <GlobalStyles.CardTitle>
              <h3>Carrier Manifests</h3>
            </GlobalStyles.CardTitle>
            <GlobalStyles.CardToolbar>
              <GlobalStyles.Button
                style={{ height: 40, width: 170, marginRight: '1em' }}
                onClick={() => setOpenCreateManifest(true)}
              >
                Create Manifest
              </GlobalStyles.Button>
              <CreateManifest open={openCreateManifest} setOpen={setOpenCreateManifest} />
              {selectedManifest && (
                <ShipmentList open={openShipmentsModal} setOpen={setOpenShipmentsModal} manifestId={selectedManifest} />
              )}
              <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus />
              <AdvancedSelect
                placeholder="Filter"
                {...{ filterOptions, setFilterOptions }}
                setDefault={setDefaultFilter}
              />
            </GlobalStyles.CardToolbar>
          </GlobalStyles.CardHeader>
          <div style={{ minHeight: 500 }}>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text center">Created</span>
                  </th>
                  <th>
                    <span className="text center">Pickup ETA</span>
                  </th>
                  <th>
                    <span className="text center">Location</span>
                  </th>
                  <th>
                    <span className="text center">Carrier</span>
                  </th>
                  <th>
                    <span className="text center">SSCC</span>
                  </th>
                  <th>
                    <span className="text center">Shipments</span>
                  </th>
                  <th>
                    <span className="text center">Weight (LBs)</span>
                  </th>
                  <th>
                    <span className="text center">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {manifests.map(
                  ({
                    id,
                    created,
                    expected_pickup_time,
                    location_barcode,
                    sscc_barcode_char,
                    carrier_name,
                    service_level_name,
                    open,
                    url,
                    total_shipments,
                    total_weight,
                  }) => (
                    <GlobalStyles.TableRow key={id}>
                      <TableCell center input={created} />
                      <TableCell
                        center
                        input={
                          <>
                            <strong style={{ fontWeight: 600 }}>
                              {new Date(expected_pickup_time).toLocaleString('en-US', dateOptions).replace(',', '')}
                            </strong>
                            <br />
                            {new Date(expected_pickup_time).toLocaleString('en-US', timeOptions)}
                          </>
                        }
                      />
                      <TableCell center input={location_barcode} />
                      <TableCell
                        center
                        input={
                          <>
                            <StatusBoxStyled style={{ width: 150, margin: 'auto' }} className="silver">
                              {carrier_name}
                            </StatusBoxStyled>
                            {service_level_name && (
                              <StatusBoxStyled style={{ width: 150, margin: '.5em auto' }} className="silver">
                                {service_level_name}
                              </StatusBoxStyled>
                            )}
                          </>
                        }
                      />
                      <TableCell center input={sscc_barcode_char} />
                      <TableCell
                        center
                        input={
                          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className="blue">
                            {total_shipments || 0}
                          </StatusBoxStyled>
                        }
                      />
                      <TableCell
                        center
                        input={
                          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className="blue">
                            {total_weight || 0}
                          </StatusBoxStyled>
                        }
                      />
                      <TableCell
                        center
                        input={
                          <div
                            className="dropdown__container"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          >
                            {url && (
                              <GlobalStyles.Tooltip data-title="Download Manifest">
                                <LargeButton onClick={() => handleDownload(url, id)}>
                                  <FontAwesomeIcon icon={faDownload} style={{ color: theme.colors.freshEggplant }} />
                                </LargeButton>
                              </GlobalStyles.Tooltip>
                            )}
                            {open && (
                              <>
                                <GlobalStyles.Tooltip data-title="Close Manifest">
                                  <LargeButton onClick={() => usePost(`/api/postage/manifest/${id}/close/`, {}, csrf)}>
                                    <FontAwesomeIcon icon={faCheckDouble} color="green" />
                                  </LargeButton>
                                </GlobalStyles.Tooltip>
                                <GlobalStyles.Tooltip data-title="Print SSCC Barcode">
                                  <LargeButton
                                    onClick={() => (window.location.href = `/api/postage/manifest/${id}/print_sscc/`)}
                                  >
                                    <FontAwesomeIcon icon={faBarcodeRead} />
                                  </LargeButton>
                                </GlobalStyles.Tooltip>
                              </>
                            )}
                            {!open && (
                              <GlobalStyles.Tooltip data-title="Reopen Manifest">
                                <LargeButton onClick={() => usePost(`/api/postage/manifest/${id}/open/`, {}, csrf)}>
                                  <FontAwesomeIcon icon={faFolderOpen} color="blue" />
                                </LargeButton>
                              </GlobalStyles.Tooltip>
                            )}
                            {userIsManager && open && (
                              <GlobalStyles.Tooltip data-title="Delete Manifest">
                                <LargeButton onClick={() => useDelete(`/api/postage/manifest/${id}/`, csrf)}>
                                  <FontAwesomeIcon icon={faTrashAlt} color="black" />
                                </LargeButton>
                              </GlobalStyles.Tooltip>
                            )}
                            <GlobalStyles.Tooltip data-title="View Shipments">
                              <LargeButton
                                onClick={() => {
                                  setSelectedManifest(id)
                                  setOpenShipmentsModal(true)
                                }}
                              >
                                <FontAwesomeIcon icon={faBoxAlt} color="blue" />
                              </LargeButton>
                            </GlobalStyles.Tooltip>
                          </div>
                        }
                      />
                    </GlobalStyles.TableRow>
                  )
                )}
              </tbody>
            </GlobalStyles.DataTable>
          </div>
          <GlobalStyles.CardFooter>
            <Pages page={page} setPage={setPage} total={totalPages} show={5} />
            <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
          </GlobalStyles.CardFooter>
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </GlobalStyles.FullPageCard>
      </Col>
      <Col xl={3}>
        <CarrierList />
      </Col>
    </Row>
  )
}

const CarrierList = () => {
  const { response, loaded, error, placeholder } = useFetch('/api/postage/manifest/carrier_breakdown/', {})
  const carriers: CarrierProps[] = loaded ? (response as any).carriers : []

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Carriers<small>{carriers?.length || 0} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div className="card-body" style={{ minHeight: 500 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text center">Carrier</span>
              </th>
              <th>
                <span className="text center">Remaining</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {carriers?.map(({ id, carrier, count }) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell input={carrier} center />
                <TableCell input={count} center />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
