import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

import { Form, InputGroup, Col, Row } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import Checkbox from '../../../components/component-items/checkbox'
import { CenteredSpinner } from '../../../components/component-items/loading-popover'
import { DateRangeStyled, DragNDrop } from './styles'
import { HoverNotes } from '../../../components/component-items/hover-notes'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar, DateRangePicker } from 'react-date-range'

import { AcceptProps } from './types'

function Accept({ file, setFile, fileTypes }: AcceptProps) {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => setFile(files[0]),
    // @ts-ignore
    accept: { '': '.csv' },
  })

  const AcceptedFilesItems = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const RejectedFilesItems = fileRejections.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <DragNDrop>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div style={{ textAlign: 'center' }}>
          <p>Drag &apos;n&apos; drop or click to select files</p>(<em>Only {fileTypes} file types</em>)
        </div>
      </div>
      <aside>
        {file && fileRejections.length ? (
          <>
            <h4>Rejected files</h4>
            <ul>{RejectedFilesItems}</ul>
          </>
        ) : null}
        {file && AcceptedFilesItems.length ? (
          <>
            <h4>Accepted files</h4>
            <ul>{AcceptedFilesItems}</ul>
          </>
        ) : null}
      </aside>
    </DragNDrop>
  )
}

export const CreateOrder = () => {
  const {
    state: { csrf, companyId, defaultWarehouse },
  } = globalState()

  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)

  const [addressName, setAddressName] = useState('')
  const [addressEmail, setAddressEmail] = useState('')
  const [remoteID, setRemoteID] = useState(null)
  const [company, setCompany] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressState, setAddressState] = useState('')
  const [addressZip, setAddressZip] = useState('')
  const [addressCountry, setAddressCountry] = useState('US')
  const [addressPhone, setAddressPhone] = useState('')
  const [warehouse, setWarehouse] = useState(defaultWarehouse.id)

  const [orderNote, setOrderNote] = useState('')
  const [customerPickup, setCustomerPickup] = useState(false)
  const [wholesalePricing, setWholesalePricing] = useState(false)
  const [ltlShipment, setLtlShipment] = useState(false)
  const [b2bOrder, setB2bOrder] = useState(false)
  const [shipBetweenDates, setShipBetweenDates] = useState<any>('before')

  const {
    response: warehouse_resp,
    error,
    loaded,
    placeholder,
  }: any = useFetch(`/api/company/${companyId}/warehouse_list/`, {})
  const warehouses = loaded ? warehouse_resp.warehouses : []

  const counries_resp: any = useFetch('/api/address/countries/', {})
  const countries = counries_resp.loaded ? counries_resp.response.countries : []

  const node = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (loaded && warehouse_resp) {
      setWarehouse(warehouse_resp.warehouseId)
    }
  }, [loaded])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    if (loading) {
      return
    }

    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    setLoading(true)
    await usePost(
      '/api/order/',
      {
        shipping_name: addressName,
        email: addressEmail,
        warehouse: warehouse,
        shipping_address_1: addressLine1,
        shipping_address_2: addressLine2,
        shipping_city: addressCity,
        shipping_state: addressState,
        shipping_zip: addressZip,
        shipping_country: addressCountry,
        shipping_phone: addressPhone,
        originator_id: remoteID,
        shipping_company: company,
        public_note: orderNote,
        shipping_customer_pickup: customerPickup,
        use_wholesale_prices: wholesalePricing,
        freight: ltlShipment,
        b_to_b: b2bOrder,
        ...(['range', 'before'].includes(shipBetweenDates)
          ? {
              ship_before: date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '',
            }
          : {}),
        ...(['range', 'after'].includes(shipBetweenDates)
          ? {
              // @ts-ignore
              ship_after: afterDate
                ? `${afterDate.getFullYear()}-${afterDate.getMonth() + 1}-${afterDate.getDate()}`
                : '',
            }
          : {}),
      },
      csrf,
      false
    )
    setLoading(false)
  }

  const onChangeBetweenDates = ({ selection }: { selection: any }) => {
    setDate(selection.endDate)
    setAfterDate(selection.startDate)
    setRangeDate([selection])
  }

  var currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  const [date, setDate] = useState<any>(currentDate)
  const [afterDate, setAfterDate] = useState<any>(null)
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  useEffect(() => {
    var numDays = 1
    var oneDay = new Date()
    if (oneDay.getDay() === 5) {
      numDays += 2
    } else if (oneDay.getDay() === 6) {
      numDays += 1
    }
    oneDay.setDate(oneDay.getDate() + numDays)
    var twoDay = new Date()
    twoDay.setDate(twoDay.getDate() + numDays + 1)
  }, [date, shipBetweenDates])

  return (
    <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Create Order</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '65%' }}>
          <Row>
            <Form.Group as={Col} md="6" className="mb-3 required">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Full Name"
                name="name"
                value={addressName}
                maxLength={100}
                onChange={(e) => setAddressName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please enter the customers full name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required mb-3">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  aria-describedby="inputGroupPrepend"
                  required
                  maxLength={100}
                  value={addressEmail}
                  onChange={(e) => setAddressEmail(e.target.value)}
                />
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control.Feedback type="invalid">Please add an email.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required mb-3">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 1"
                maxLength={100}
                name="line1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 2"
                maxLength={100}
                name="line2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                maxLength={100}
                value={addressCity}
                onChange={(e) => setAddressCity(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid city.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                maxLength={40}
                value={addressState}
                onChange={(e) => setAddressState(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required mb-3">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip"
                name="zip"
                maxLength={10}
                value={addressZip}
                onChange={(e) => setAddressZip(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip. If there is none, enter 000000
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Select name="country" value={addressCountry} onChange={(e) => setAddressCountry(e.target.value)}>
                {Object.keys(countries).map((c, id) => (
                  <option value={c} key={id}>
                    {countries[c]}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid country.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required mb-3">
              <Form.Label>Warehouse</Form.Label>
              <Form.Select name="warehouse" value={warehouse} onChange={(e: any) => setWarehouse(e.target.value)}>
                {warehouses?.map((w: any, id: number) => (
                  <option value={w.id} key={id}>
                    {w.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid country.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                name="tel"
                maxLength={100}
                value={addressPhone}
                onChange={(e) => setAddressPhone(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>Remote ID / PO Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Remote ID"
                value={remoteID || ''}
                onChange={(e: any) => setRemoteID(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="business"
                placeholder="Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12" className="form-group" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Order Notes (Shown on Packing Slip)</Form.Label>
              <Form.Control
                as="textarea"
                maxLength={1000}
                rows={3}
                value={orderNote}
                onChange={(e) => setOrderNote(e.target.value)}
              />
            </Form.Group>
          </Row>
          <InputGroup style={{ marginTop: '1em' }}>
            <Checkbox selected={customerPickup} setSelected={() => setCustomerPickup(!customerPickup)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Customer Pickup</p>
          </InputGroup>
          <InputGroup>
            <Checkbox selected={wholesalePricing} setSelected={() => setWholesalePricing(!wholesalePricing)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Use Wholesale Pricing</p>
          </InputGroup>
          <InputGroup>
            <Checkbox selected={ltlShipment} setSelected={() => setLtlShipment(!ltlShipment)} />
            <p style={{ margin: '0px 1em 1em' }}>
              <HoverNotes
                description="Use this option if you would like Kase to handle the routing for your LTL (Less Than Truckload) shipment."
                title="LTL / Freight Shipment"
              />
            </p>
          </InputGroup>
          <InputGroup>
            <Checkbox selected={b2bOrder} setSelected={() => setB2bOrder(!b2bOrder)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Mark as B2B / Wholesale Order</p>
          </InputGroup>
          {b2bOrder ? (
            <>
              <div className="mb-3">
                <Form.Check
                  inline
                  label="After date"
                  name="shipType"
                  type="radio"
                  value="after"
                  checked={shipBetweenDates === 'after'}
                  onChange={() => setShipBetweenDates('after')}
                />
                <Form.Check
                  inline
                  name="shipType"
                  label="Before date"
                  type="radio"
                  value="before"
                  checked={shipBetweenDates === 'before'}
                  onChange={() => setShipBetweenDates('before')}
                />
                <Form.Check
                  inline
                  name="shipType"
                  label="Between dates"
                  type="radio"
                  value="range"
                  checked={shipBetweenDates === 'range'}
                  onChange={() => setShipBetweenDates('range')}
                />
              </div>
              {shipBetweenDates === 'range' ? (
                <DateRangeStyled>
                  <DateRangePicker
                    ranges={rangeDate}
                    minDate={currentDate}
                    // @ts-ignore
                    onChange={onChangeBetweenDates}
                    showSelectionPreview
                    moveRangeOnFirstSelection
                    direction="horizontal"
                    className="hide-in-percy"
                  />
                </DateRangeStyled>
              ) : (
                <DateRangeStyled>
                  <Calendar
                    onChange={(item: any) => (shipBetweenDates === 'after' ? setAfterDate(item) : setDate(item))}
                    date={shipBetweenDates === 'after' ? afterDate : date}
                    minDate={currentDate}
                    showMonthAndYearPickers={false}
                    className="hide-in-percy"
                  />
                </DateRangeStyled>
              )}
            </>
          ) : null}
          {!loading ? (
            <GlobalStyles.Button onClick={(e) => handleSubmit(e)} style={{ minWidth: 150, margin: 0, float: 'right' }}>
              Next
            </GlobalStyles.Button>
          ) : (
            <GlobalStyles.Button style={{ minWidth: 150, margin: 0, float: 'right', cursor: 'auto' }}>
              <CenteredSpinner />
              &nbsp;
            </GlobalStyles.Button>
          )}
        </div>
      </GlobalStyles.FullPageCard>
    </Form>
  )
}
