import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { globalState } from '../../../../store'
import { useFetch, usePost } from '../../../../components/component-items/helpers'
import { Helmet } from 'react-helmet'
import { Form, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { theme as customTheme } from '../../../../theme/KaseTheme'

// Components
import GlobalStyles from '../../../../components/component-items/styles'

const SlotNumber = styled.h4`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.2em;
  border-bottom: solid #c5c5c5 0.5px;
  width: min-content;
`

export const MatrixSlotting = () => {
  const {
    state: { csrf },
  } = globalState()
  const { id: batch, inventory_item } = useParams<{ id: string; inventory_item: string }>()
  const slottingURL = `/api/inventory/batch/${batch}/slot/?inventory_item=${inventory_item}`
  const res: any = useFetch(slottingURL, { redirect: 'follow' })
  const resp: any = res.response

  const [activeSlots, setActiveSlots] = useState([])
  useEffect(() => {
    if (res.loaded) {
      setActiveSlots(resp.active_slots)
    }
  }, [res.loaded])

  const company = res.loaded ? resp.company : 'Uqora'
  const img = res.loaded ? resp.img : ''
  const name = res.loaded ? resp.name : ''

  const levels = res.loaded ? [...Array(resp.levels).keys()] : [0, 1, 2, 3, 4]
  const slots = res.loaded ? [...Array(resp.slots).keys()] : [0, 1, 2, 3, 4]
  const width = 100 / (slots.length || 1)
  const height = 60 / (levels.length || 1)

  const handleSubmit = async (id: string | number) => {
    const result = await usePost(slottingURL, { ...{ id } }, csrf, false)
    if (result?.active_slots) {
      setActiveSlots(result.active_slots)
    }
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, maximum-scale=0.8" />
      </Helmet>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>
              PUT ITEMS | <u>{name}</u>
            </h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardTitle>
            <div style={{ textAlign: 'right' }}>
              Batch Picking for <u>{company}</u>
            </div>
          </GlobalStyles.CardTitle>
          <Row style={{ float: 'right', margin: '.5em' }}>
            <Form.Group as={Col} col="4" style={{ display: 'flex' }}>
              <img style={{ width: 'auto', height: 50, margin: 'auto' }} src={img} />
            </Form.Group>
          </Row>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '90%' }}>
          {levels.map((level) => (
            <div style={{ display: 'flex' }} key={level}>
              {slots.map((slot) => {
                // If there are completed and pending items, we want to update the pending items
                const activeSlotTypes =
                  activeSlots.filter((a) => a['level'] == level + 1 && a['slot'] == slot + 1) || []
                let activeSlot: any = activeSlotTypes[0] || {}
                if (activeSlotTypes.length > 1) {
                  activeSlot = activeSlotTypes.find((a) => a['pending'] === true) || {}
                }
                const id = activeSlot['id']
                const quantity = activeSlot['quantity']
                const pending = activeSlot['pending']
                return (
                  <button
                    style={{
                      backgroundColor: `${
                        quantity ? (pending ? customTheme.colors.lightOrange : customTheme.colors.neonGreen) : '#e3edf3'
                      }`,
                      border: 'solid grey 1px',
                      width: `${width}%`,
                      height: `${height}vh`,
                      display: 'flex',
                      position: 'relative',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: `${quantity && pending ? 'pointer' : 'auto'}`,
                    }}
                    onClick={() => {
                      if (quantity && pending) {
                        handleSubmit(id)
                      }
                    }}
                    key={`${level}-${slot}`}
                    type="button"
                  >
                    <SlotNumber>{level * slots.length + slot + 1}</SlotNumber>
                    <h2
                      style={{
                        fontSize: `min(${width * 0.6}vw,6em)`,
                        color: pending ? customTheme.colors.dodgerBlue : customTheme.colors.darkPurple,
                      }}
                    >
                      {quantity}
                    </h2>
                  </button>
                )
              })}
            </div>
          ))}
        </div>
      </GlobalStyles.FullPageCard>
    </>
  )
}
