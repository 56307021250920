import React, { useEffect, useState } from 'react'
import { notify, useDelete, useFetch, usePost } from '../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { globalState } from '../../../store'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faPlay, faStars, faTrashAlt, faLock } from '@fortawesome/pro-duotone-svg-icons'
import CardLoading from '../../../components/component-items/loading-popover'
import { Pages } from '../../../components/component-items/pager'
import { OrderAutomationEventTypes } from '../../../enums'
import { AutomationPreview } from './automation/preview'
import { Form } from 'react-bootstrap'

export const AutomationList = () => {
  const {
    state: { csrf },
  } = globalState()

  // Pagination
  const [page, setPage] = useState(1)
  const pageLimit = 20
  const offset = (page - 1) * pageLimit
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const url = `/api/core/automation/?offset=${offset}`
  const { response: resp, loaded, error } = useFetch(url, {})

  const automations = resp?.results || []

  useEffect(() => {
    if (loaded) {
      setTotal(resp.count)
      setTotalPages(Math.ceil(resp.count / pageLimit))
    }
  }, [loaded])

  const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric' }

  const timeOptions: any = { hour: 'numeric', minute: 'numeric', hour12: true }

  const [showPreview, setShowPreview] = useState(false)
  const [activeAutomation, setActiveAutomation] = useState<any>({})

  const runAutomation = (id: number) => {
    const automation = automations.find((a: any) => a.id === id)
    usePost('/api/core/automation/run/', { ...automation }, csrf, false, true).then((response) => {
      if (response?.success) {
        notify({ type: 'success', message: 'Automation Ran Successfully' })
      }
    })
  }

  const enableDisableAutomation = (id: number) => (e: any) => {
    usePost(`/api/core/automation/${id}/enable/`, {}, csrf, false, true).then((response) => {
      if (response?.success) {
        notify({ type: 'success', message: 'Automation Updated Successfully' })
      }
    })
  }

  return (
    <GlobalStyles.FullPageCard style={{ display: 'block', marginTop: '1em', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            <FontAwesomeIcon icon={faStars} style={{ color: '#001682', marginRight: '.5em' }} />
            Automations<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 100, width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Name</span>
              </th>
              <th>
                <span className="text center">Created</span>
              </th>
              <th>
                <span className="text center">Category</span>
              </th>
              <th>
                <span className="text center">Trigger Event</span>
              </th>
              <th>
                <span className="text center">Action</span>
              </th>
              <th>
                <span className="text center">Enabled</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {automations.map(({ id, created, enabled, category, name, isPrivate, event, action }: any) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell
                  input={
                    <span style={{ textTransform: 'capitalize' }}>
                      {isPrivate && <FontAwesomeIcon icon={faLock} />} {name}
                    </span>
                  }
                />
                <TableCell
                  center
                  input={
                    <>
                      <strong style={{ fontWeight: 600 }}>
                        {new Date(created).toLocaleString('en-US', dateOptions).replace(',', '')}
                      </strong>
                      <br />
                      {new Date(created).toLocaleString('en-US', timeOptions)}
                    </>
                  }
                />
                <TableCell
                  center
                  input={<span style={{ textTransform: 'capitalize' }}>{category?.replace(/_/g, '\u00A0')}</span>}
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={'blue'}
                      style={{ minWidth: 80, margin: 'auto', textTransform: 'capitalize' }}
                    >
                      {event?.category === 'now' ? 'Manual' : event?.category?.replace(/_/g, '\u00A0')}
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={'royalshine'}
                      style={{ minWidth: 80, margin: 'auto', textTransform: 'capitalize' }}
                    >
                      {action?.type === 'logical_and' ? 'Multi Action' : action?.type?.replace(/_/g, '\u00A0')}
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    event?.category !== OrderAutomationEventTypes.Now && (
                      <Form.Check
                        type="switch"
                        id={'active-' + id}
                        defaultChecked={enabled}
                        onChange={enableDisableAutomation(id)}
                      />
                    )
                  }
                />

                <TableCell center>
                  <div className="dropdown__container" style={{ display: 'flex', justifyContent: 'center' }}>
                    <>
                      <GlobalStyles.Tooltip data-title="Preview Automation">
                        <button
                          onClick={() => {
                            setShowPreview(true)
                            setActiveAutomation(automations.find((a: any) => a.id === id))
                          }}
                        >
                          <FontAwesomeIcon icon={faList} />
                        </button>
                      </GlobalStyles.Tooltip>
                      {event.category === OrderAutomationEventTypes.Now && (
                        <GlobalStyles.Tooltip data-title="Run Automation">
                          <button onClick={() => runAutomation(id)}>
                            <FontAwesomeIcon icon={faPlay} />
                          </button>
                        </GlobalStyles.Tooltip>
                      )}
                    </>
                  </div>
                  <div className="dropdown__container" style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* TODO - Add AutomationLogic Preview */}
                    {/* <GlobalStyles.Tooltip data-title="View Automation Details">
                        <button onClick={() => null}>
                          <FontAwesomeIcon icon={faCode} />
                        </button>
                      </GlobalStyles.Tooltip> */}
                    <GlobalStyles.Tooltip data-title="Delete Automation">
                      <button onClick={() => useDelete(`/api/core/automation/${id}/`, csrf)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </GlobalStyles.Tooltip>
                  </div>
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter style={{ margin: 'auto' }}>
        <Pages page={page} setPage={setPage} total={totalPages} show={5} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading error={error} /> : null}
      <AutomationPreview
        {...{
          open: showPreview,
          setOpen: setShowPreview,
          category: activeAutomation.category,
          condition: activeAutomation.condition,
          event: activeAutomation.event,
          action: activeAutomation.action,
        }}
      />
    </GlobalStyles.FullPageCard>
  )
}
