import React, { useState, useEffect, useContext, useRef } from 'react'
import { toast } from 'react-toastify'
import { store } from '../../../store'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useFetch, usePost, notify, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import Clipboard from 'react-clipboard-animation'
import { useTheme } from 'styled-components'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import Checkbox from '../../../components/component-items/checkbox'
import Select from 'react-select'
import { HoverNotes } from '../../../components/component-items/hover-notes'
import { TableRow } from '../../orders/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle, faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'

export const CompanySettings = () => {
  const globalState = useContext(store)
  const { state } = globalState
  const isWarehouse = state.userIsWarehouse
  return (
    <>
      <Settings />
      {isWarehouse ? <CompanyWarehouseSettings /> : null}
      <CompanyAddress />
      <APIKeys />
    </>
  )
}

export const Settings = () => {
  const defaultSettings = {
    companyName: '',
    supportEmail: '',
    orderMaturation: 120,
    minShelfLife: 90,
    maxShippingAmount: '',
    emailCustomer: true,
    holdOrderReservations: true,
    useIntegrationPrices: false,
    dontShipOutsideUsa: false,
  }
  const [settings, setSettings] = useState<any>(defaultSettings)
  const [validated, setValidated] = useState(false)
  const {
    state: { csrf, companyId },
  } = globalState()

  const res = useFetch(`/api/company/${companyId}/settings/`, {})
  const loaded = res.loaded
  const resp: any = loaded ? res.response : {}

  useEffect(() => {
    if (loaded) {
      setSettings(resp)
    }
  }, [loaded])

  const handleSubmit = (event: any) => {
    const form = event?.currentTarget

    event?.preventDefault()
    event?.stopPropagation()
    setValidated(true)

    if (!form || form?.checkValidity() === false) {
      return
    }

    usePut(`/api/company/${companyId}/settings/`, { ...settings }, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Company Settings</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button>Update</GlobalStyles.Button>
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ padding: '2em', maxWidth: 800, margin: 'auto' }}>
          <Row>
            <Form.Group as={Col} md="6" controlId="companyName" className="required">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                required
                type="name"
                placeholder="Company Name"
                value={settings.companyName}
                onChange={(e) => setSettings({ ...settings, companyName: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter the companies full name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="supportEmail" className="required">
              <Form.Label>Support Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  aria-describedby="inputGroupPrepend"
                  required
                  value={settings.supportEmail}
                  onChange={(e) => setSettings({ ...settings, supportEmail: e.target.value })}
                />
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control.Feedback type="invalid">Please choose a support email.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row style={{ alignItems: 'baseline' }}>
            <Form.Group as={Col} md="6" controlId="orderMaturation" className="required">
              <Form.Label>Order Maturation Period (Min)</Form.Label>
              <Form.Control
                type="number"
                min="15"
                placeholder="Order Maturation Period (Min)"
                value={settings.orderMaturation}
                onChange={(e) => setSettings({ ...settings, orderMaturation: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">
                Order Maturation Period must be at least 15 minutes.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="minShelfLife" className="required">
              <Form.Label>Minimum Shelf Life for Lot Controlled Inventory</Form.Label>
              <Form.Control
                type="number"
                placeholder="Min Shelf Life (Days)"
                value={settings.minShelfLife}
                onChange={(e) => setSettings({ ...settings, minShelfLife: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a minimum shelf life.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="maxShippingAmount">
              <Form.Label>
                <GlobalStyles.Tooltip
                  data-title="Requires confirmation if shipping exceeds limit"
                  style={{ borderBottom: 'dashed 1px' }}
                >
                  Max Shipping Amount
                </GlobalStyles.Tooltip>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Max Shipping Amount"
                value={settings.maxShippingAmount}
                onChange={(e) => setSettings({ ...settings, maxShippingAmount: e.target.value })}
              />
            </Form.Group>
          </Row>
          <InputGroup style={{ paddingTop: '1em' }}>
            <Checkbox
              selected={settings.emailCustomer}
              setSelected={() => setSettings({ ...settings, emailCustomer: !settings.emailCustomer })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Send Order Confirmation Email (Excludes Shopify)</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.useOnlyInStockWarehouses}
              setSelected={() =>
                setSettings({ ...settings, useOnlyInStockWarehouses: !settings.useOnlyInStockWarehouses })
              }
            />
            <span style={{ margin: '0 1em 1em' }}>
              <HoverNotes
                description="Only applies to multi-warehouse merchants."
                title="Use Only In-Stock Warehouses"
              />
            </span>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.holdOrderReservations}
              setSelected={() => setSettings({ ...settings, holdOrderReservations: !settings.holdOrderReservations })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>
              Hold onto Reservations for Orders with Inventory Unavailable
            </p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.useIntegrationPrices}
              setSelected={() => setSettings({ ...settings, useIntegrationPrices: !settings.useIntegrationPrices })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Use Shopify Prices for Customs Declaration</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.dontShipOutsideUsa}
              setSelected={() => setSettings({ ...settings, dontShipOutsideUsa: !settings.dontShipOutsideUsa })}
            />
            <p style={{ padding: '0 1em' }}>Only Ship within USA</p>
          </InputGroup>
        </div>
      </Form>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
  }
`

export const CompanyWarehouseSettings = () => {
  const defaultSettings = {
    warehouse: '',
    fulfillmentName: '',
    activeWarehouses: [],
    orderingWarehouses: [],
    maxDeliveryDays: '',
    amazonSellerAccount: '',
    grading: '30',
    active: true,
    enableFulfillment: false,
    multiWarehouse: false,
    testing: false,
    wireTransferMerchant: false,
    fastWave: false,
    pauseReplacementOrders: false,
    usePassport: false,
    useAmazonShipping: false,
  }
  const [settings, setSettings] = useState(defaultSettings)
  const [validated, setValidated] = useState(false)

  const {
    state: { csrf, companyId },
  } = globalState()

  const res = useFetch(`/api/company/${companyId}/warehouse_settings/`, {})
  const loaded = res.loaded
  const resp: any = loaded ? res.response : {}

  const warehouseList = loaded ? resp.warehouses : []
  var warehouses = warehouseList.map((w: any) => ({ value: w.id, label: w.name }))

  useEffect(() => {
    if (loaded) {
      const settings = resp.settings
      const activeDefaultList = resp.settings.activeWarehouses.map((w: any) => ({ value: w.id, label: w.name }))
      settings.activeWarehouses = activeDefaultList
      const orderDefaultList = resp.settings.orderingWarehouses.map((w: any) => ({ value: w.id, label: w.name }))
      settings.orderingWarehouses = orderDefaultList
      setSettings(settings)
    }
  }, [loaded])

  const handleSubmit = (event: any) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }

    if (settings.multiWarehouse && settings.activeWarehouses.length < 2) {
      notify({ type: 'error', message: 'Multi warehouse merchants must have at least two warehouses active' })
      return
    }

    usePost(`/api/company/${companyId}/warehouse_settings/`, { ...settings }, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Company Warehouse Settings</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button type="submit">Update</GlobalStyles.Button>
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ padding: '2em', maxWidth: 800, margin: 'auto' }}>
          <Row>
            <Form.Group as={Col} md="6" controlId="warehouse" className="required">
              <Form.Label>Default Warehouse</Form.Label>
              <Form.Select
                value={settings.warehouse}
                onChange={(e) => setSettings({ ...settings, warehouse: e.target.value })}
              >
                {warehouseList.map((w: any) => (
                  <option value={w.id} key={w.id}>
                    {w.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid warehouse.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="activeWarehouses">
              <Form.Label>
                <HoverNotes description="Only applies to multi-warehouse merchants." title="Active Warehouses" />
              </Form.Label>
              <MultiSelect>
                <Select
                  options={warehouses}
                  value={settings.activeWarehouses}
                  onChange={(e: any) => setSettings({ ...settings, activeWarehouses: e })}
                  closeMenuOnSelect={false}
                  isMulti
                  className="basic-multi-select"
                />
              </MultiSelect>
              <Form.Control.Feedback type="invalid">
                Please select at least two warehouses for multi-warehouse merchants.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="orderingWarehouses">
              <Form.Label>
                <HoverNotes
                  description="Imported orders will choose from this list."
                  title="Warehouses Accepting Orders"
                />
              </Form.Label>
              <MultiSelect>
                <Select
                  options={settings.activeWarehouses}
                  value={settings.orderingWarehouses}
                  onChange={(e: any) => setSettings({ ...settings, orderingWarehouses: e })}
                  closeMenuOnSelect={false}
                  isMulti
                  className="basic-multi-select"
                />
              </MultiSelect>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="maxDeliveryDays">
              <Form.Label>Maximum number of delivery days for orders</Form.Label>
              <Form.Control
                type="number"
                placeholder="Max Delivery Days"
                value={settings.maxDeliveryDays || ''}
                onChange={(e) => setSettings({ ...settings, maxDeliveryDays: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="fulfillmentName" className="required">
              <Form.Label>Fulfillment Name</Form.Label>
              <Form.Control
                required
                type="name"
                placeholder="Fulfillment Name"
                value={settings.fulfillmentName}
                onChange={(e) => setSettings({ ...settings, fulfillmentName: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter the fulfillment name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="grade" className="required">
              <Form.Label>Company Grading</Form.Label>
              <Form.Select
                value={settings.grading}
                onChange={(e) => setSettings({ ...settings, grading: e.target.value })}
              >
                <option value={'10'}>A Grade</option>
                <option value={'20'}>B Grade</option>
                <option value={'30'}>C Grade</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please select the company grading.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="grade">
              <Form.Label>Amazon Seller ID</Form.Label>
              <Form.Control
                placeholder="Amazon Seller ID"
                value={settings.amazonSellerAccount || ''}
                onChange={(e) => setSettings({ ...settings, amazonSellerAccount: e.target.value })}
              />
            </Form.Group>
          </Row>
          <InputGroup style={{ paddingTop: '1em' }}>
            <Checkbox
              selected={settings.active}
              setSelected={() => setSettings({ ...settings, active: !settings.active })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Company Is Active</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.enableFulfillment}
              setSelected={() => setSettings({ ...settings, enableFulfillment: !settings.enableFulfillment })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Enable Fulfillment</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.multiWarehouse}
              setSelected={() => setSettings({ ...settings, multiWarehouse: !settings.multiWarehouse })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>MultiWarehouse Merchant</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.testing}
              setSelected={() => setSettings({ ...settings, testing: !settings.testing })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Test Company</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.wireTransferMerchant}
              setSelected={() => setSettings({ ...settings, wireTransferMerchant: !settings.wireTransferMerchant })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Wire Transfer Merchant</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.fastWave}
              setSelected={() => setSettings({ ...settings, fastWave: !settings.fastWave })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Enable Fast Wave</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.pauseReplacementOrders}
              setSelected={() => setSettings({ ...settings, pauseReplacementOrders: !settings.pauseReplacementOrders })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Pause Replacement Orders</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.usePassport}
              setSelected={() => setSettings({ ...settings, usePassport: !settings.usePassport })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Enable Passport for Shipping</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={settings.useAmazonShipping}
              setSelected={() => setSettings({ ...settings, useAmazonShipping: !settings.useAmazonShipping })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Enable Amazon for Shipping</p>
          </InputGroup>
        </div>
      </Form>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

export const CompanyAddress = () => {
  const defaultAddress = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    phone: '',
  }
  const [address, setAddress] = useState(defaultAddress)
  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement>(null)

  const {
    state: { csrf, companyId },
  } = globalState()

  const res = useFetch(`/api/company/${companyId}/address/`, {})
  const loaded = res.loaded
  const resp: any = loaded ? res.response : {}

  const countries = loaded ? resp.countries : []

  useEffect(() => {
    if (loaded) {
      setAddress(resp.address)
    }
  }, [loaded])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost(`/api/company/${companyId}/address/`, { ...address }, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Company Address</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button onClick={(e) => handleSubmit(e)}>Update</GlobalStyles.Button>
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ padding: '2em', width: '-webkit-fill-available', maxWidth: 800, margin: 'auto' }}>
          <Row>
            <Form.Group as={Col} md="6" controlId="line1" className="required">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 1"
                value={address.line1}
                onChange={(e) => setAddress({ ...address, line1: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="line2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 2"
                value={address.line2}
                onChange={(e) => setAddress({ ...address, line2: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" controlId="city" className="required">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                value={address.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid city.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="state" className="required">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                value={address.state}
                onChange={(e) => setAddress({ ...address, state: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="zip" className="required">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip"
                value={address.zip}
                onChange={(e) => setAddress({ ...address, zip: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip. If there is none, enter 000000
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" controlId="country" className="required">
              <Form.Label>Country</Form.Label>
              <Form.Select
                value={address.country}
                onChange={(e) => setAddress({ ...address, country: e.target.value })}
              >
                {Object.keys(countries).map((c, id) => (
                  <option value={c} key={id}>
                    {countries[c]}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid country.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="phone" className="required">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                value={address.phone}
                onChange={(e) => setAddress({ ...address, phone: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </div>
      </Form>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

export const APIKeys = () => {
  const theme = useTheme()
  const [copied, setCopied] = useState(false)

  const {
    state: { csrf, companyId },
  } = globalState()

  const { response: resp, loaded, error, placeholder }: any = useFetch(`/api/company/${companyId}/keys/`, {})
  const apiKey = loaded ? resp.apiKey : ''

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>API Keys</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {!apiKey && (
            <GlobalStyles.Button onClick={() => usePost(`/api/company/${companyId}/keys/`, {}, csrf)}>
              Create API Key
            </GlobalStyles.Button>
          )}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 300, padding: '2em', width: '-webkit-fill-available', maxWidth: 700, margin: 'auto' }}>
        <StatusBarStyled style={{ backgroundColor: theme.colors.ghostWhite }}>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faInfoSquare} style={{ color: theme.colors.freshEggplant }} />
            </div>
            <div className="status-text">
              An <strong>API Key</strong> will grant access to Kase's API. Documentation can be found{' '}
              <Link target="_blank" to={'/api/docs/'}>
                here
              </Link>
              .
            </div>
          </div>
        </StatusBarStyled>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">API Key</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {apiKey && (
              <TableRow>
                <TableCell input={'************' + apiKey.substr(apiKey.length - 4)} />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container" style={{ fontSize: '1.2em', minWidth: 80 }}>
                      <button onClick={() => usePost(`/api/company/${companyId}/keys/`, {}, csrf)}>
                        <GlobalStyles.Tooltip data-title="Regenerate API Key">
                          <FontAwesomeIcon icon={faRecycle} />
                        </GlobalStyles.Tooltip>
                      </button>
                      <br />
                      <button>
                        <GlobalStyles.Tooltip data-title="Copy to clipboard">
                          <Clipboard copied={copied} setCopied={setCopied} text={apiKey} color="grey" />
                        </GlobalStyles.Tooltip>
                      </button>
                    </div>
                  }
                />
              </TableRow>
            )}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
