import React, { useEffect, useState } from 'react'

import { StatusBoxStyled } from '../../../components/component-items/status-box'
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import CardLoading from '../../../components/component-items/loading-popover'
import { useTheme } from 'styled-components'

export const Packaging = () => {
  const theme = useTheme()

  const unitCost = '0.25'
  const [resp, setResp] = useState<any>({})
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    fetch('/api/product/packaging/warehouse_packaging/')
      .then((response) => response.json())
      .then((json) => {
        setResp(json)
        setLoaded(true)
      })
      .catch((error) => {
        setLoaded(true)
        setError('ERROR: ' + error)
      })
  }, [])

  const packages = loaded ? resp.packages : []

  return (
    <>
      <StatusBoxStyled className="silver" style={{ margin: '0 auto 2em', textAlign: 'center' }}>
        Note: We can also help you design your branded shippers
      </StatusBoxStyled>
      <GlobalStyles.DataTable style={{ margin: '3em auto 5em', width: 500 }}>
        <tbody>
          <GlobalStyles.TableRow>
            <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>
              Custom Packaging Costs
            </TableCell>
            <TableCell
              center
              input={
                <StatusBoxStyled className="blue" style={{ width: 200, margin: 'auto' }}>
                  ${unitCost} processing + storage
                </StatusBoxStyled>
              }
            />
          </GlobalStyles.TableRow>
        </tbody>
      </GlobalStyles.DataTable>
      <GlobalStyles.DataTable style={{ marginTop: '2em' }}>
        <thead>
          <tr>
            <th colSpan={5}>
              <span className="text center" style={{ fontSize: '1.2em' }}>
                Kase Packaging
              </span>
            </th>
          </tr>
          <tr>
            <th>
              <span className="text center"></span>
            </th>
            <th>
              <span className="text center">SKU</span>
            </th>
            <th>
              <span className="text center">Title</span>
            </th>
            <th>
              <span className="text center">Type</span>
            </th>
            <th>
              <span className="text center">Price</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {packages
            .sort((a: any) => a.sku)
            .map(({ id, price, sku, img, name, type }: any) => (
              <GlobalStyles.TableRow id={id} key={id}>
                <TableCell center input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />} />
                <TableCell center input={sku} />
                <TableCell center input={name} />
                <TableCell center input={type} />
                <TableCell center>
                  <StatusBoxStyled className={'blue'} style={{ width: 90, margin: 'auto' }}>
                    ${price}
                  </StatusBoxStyled>
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
        </tbody>
      </GlobalStyles.DataTable>
      {!loaded ? <CardLoading error={!!error} text={error} /> : null}
    </>
  )
}
