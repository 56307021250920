import React, { useState, useRef, useEffect } from 'react'
import { globalState } from '../../../../store'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import { notify, usePost, useDelete } from '../../../../components/component-items/helpers'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'
import GenericModal from '../../../../components/component-items/modal'

// Types
import { TOTPProps } from './types'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'

export const TOTP = ({ setTotpEnabled, totpOpen, setTotpOpen }: TOTPProps) => {
  const {
    state: { csrf },
  } = globalState()
  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement>(null)
  const [code, setCode] = useState('')
  const [qrCode, setQrCode] = useState('')

  useEffect(() => {
    // Fetch the QR code image
    if (!qrCode && totpOpen) {
      fetch('/api/2fa/setup/totp/')
        .then((response) => {
          if (response.ok) {
            return response.blob()
          } else {
            throw new Error('Failed to generate QR code')
          }
        })
        .then((imageBlob) => {
          const qrCodeUrl = URL.createObjectURL(imageBlob)
          setQrCode(qrCodeUrl)
        })
        .catch((error) => {
          notify({
            type: 'success',
            message: 'There was an issue fetching your QR code. Please refresh the page and try again.',
          })
        })
    }
  }, [totpOpen])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)
    if (node?.current?.checkValidity() === false) {
      return
    }
    // Send totp code to server
    const response = await usePost('/api/2fa/verify/totp/', { code: code }, csrf, false)
    if (response.success) {
      notify({ type: 'success', message: 'One-Time Password setup successfully' })
      setTotpOpen(false)
      setTotpEnabled(true)
    }
  }

  return (
    <GenericModal
      show={totpOpen}
      onHide={() => setTotpOpen(false)}
      size="lg"
      heading={<Modal.Title>One-Time Password Setup</Modal.Title>}
      buttons={
        <div style={{ margin: '1em 0' }}>
          <GlobalStyles.Button onClick={handleSubmit} style={{ minWidth: 150 }}>
            Submit
          </GlobalStyles.Button>
        </div>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <div style={{ padding: '2em', maxWidth: 500, margin: 'auto' }}>
          <Row style={{ justifyContent: 'center' }}>
            <Form.Group as={Col}>
              <StatusBarStyled style={{ backgroundColor: '#f4f7ff', marginBottom: 0 }}>
                <div className="header-item">
                  <div className="status-icon">
                    <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
                  </div>
                  <div className="status-text">
                    <strong style={{ fontWeight: 500 }}>Note</strong>: Download an authenticator app to your mobile
                    phone. Scan the below QR code with your device's camera and save it to your authenticator app. A
                    guide for 2FA app setup can be found{' '}
                    <a href="https://help.shippingtree.co/article/71-using-2fa-apps" rel="noreferrer" target="_blank">
                      here
                    </a>
                    .
                  </div>
                </div>
              </StatusBarStyled>
              <div style={{ textAlign: 'center', margin: '1em 0' }}>
                <img style={{ maxWidth: '80%' }} src={qrCode} alt="QR Code failed to load" />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>6-digit Code from Authenticator App</Form.Label>
              <Form.Control
                type="text"
                placeholder="000000"
                maxLength={6}
                minLength={6}
                value={code}
                onChange={(e: any) => setCode(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">The code must be a 6-digit number</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </div>
      </Form>
    </GenericModal>
  )
}
